import axios from "axios";
const API = axios.create({ baseURL: "https://nsc-back.onrender.com/api" });

API.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("profile")).token
    }`;
  }
  return req;
});

export const signIn = (formData) => API.post("/user/login", formData);

// student
export const studentSingnIn = (formData) =>
  API.post("/student/login", formData);

// student
export const studentFacultyAndSubjects = (id) =>
  API.post(`/student/details/${id}`);
// teacher
export const teacherSingnIn = (formData) =>
  API.post("/teacher/login", formData);

// banner
export const banner = (formData) => API.post("/banner", formData);
export const banners = () => API.get("/banners");
export const singleBanner = (id) => API.get(`/banner/${id}`);
export const updateBanner = (id, formData) =>
  API.put(`/banner/${id}`, formData);
export const bannerImage = (id, formData) =>
  API.post(`/bannerFile/${id}`, formData);
export const deleteBanner = (id) => API.delete(`/banner/${id}`);

// faculties
export const faculty = (formData) => API.post("/faculty", formData);
export const faculties = () => API.get("/faculties");
export const singleFaculty = (id) => API.get(`/faculty/${id}`);
export const updateFaculty = (id, formData) =>
  API.put(`/faculty/${id}`, formData);
export const facultyImageUpdate = (id, formData) =>
  API.post(`/facultyFile/${id}`, formData);
export const deleteFaculty = (id) => API.delete(`/faculty/${id}`);

// about

export const about = (formData) => API.post("/about", formData);
export const abouts = () => API.get("/abouts");
export const singleAbout = (id) => API.get(`/about/${id}`);
export const updateAbout = (id, formData) => API.put(`/about/${id}`, formData);
export const aboutImageUpdate = (id, formData) =>
  API.post(`/aboutFile/${id}`, formData);
export const deleteAbout = (id) => API.delete(`/about/${id}`);

// principle message

export const pmessage = (formData) => API.post("/pmessage", formData);
export const pmessages = () => API.get("/pmessages");
export const singlePmessage = (id) => API.get(`/pmessage/${id}`);
export const updatePmessage = (id, formData) =>
  API.put(`/pmessage/${id}`, formData);
export const pmessageImageUpdate = (id, formData) =>
  API.post(`/pmessageFile/${id}`, formData);
export const deletePmessage = (id) => API.delete(`/pmessage/${id}`);

// admission

export const admission = (formData) => API.post("/admission", formData);
export const admissions = () => API.get("/admissions");
export const singleAdmission = (id) => API.get(`/admission/${id}`);
export const updateAdmission = (id, formData) =>
  API.put(`/admission/${id}`, formData);
export const admissionImageUpdate = (id, formData) =>
  API.post(`/admissionFile/${id}`, formData);
export const deleteAdmission = (id) => API.delete(`/admission/${id}`);

// news and events

export const news = (formData) => API.post("/news", formData);
export const newss = () => API.get("/newss");
export const singleNews = (id) => API.get(`/news/${id}`);
export const updateNews = (id, formData) => API.put(`/news/${id}`, formData);
export const newsImageUpdate = (id, formData) =>
  API.post(`/newsFile/${id}`, formData);
export const deleteNews = (id) => API.delete(`/news/${id}`);

// faq

export const faq = (formData) => API.post("/faq", formData);
export const faqs = () => API.get("/faqs");
export const singleFaq = (id) => API.get(`/faq/${id}`);
export const updateFaq = (id, formData) => API.put(`/faq/${id}`, formData);
export const faqImageUpdate = (id, formData) =>
  API.post(`/faqFile/${id}`, formData);
export const deleteFaq = (id) => API.delete(`/faq/${id}`);

// contact

export const contact = (formData) => API.post("/contact", formData);
export const contacts = () => API.get("/contacts");
export const singlecontact = (id) => API.get(`/contact/${id}`);
export const updatecontact = (id, isView) =>
  API.put(`/contact/${id}`, { isView: isView });
export const deletecontact = (id) => API.delete(`/contact/${id}`);

// application

export const application = (formData) => API.post("/application", formData);
export const applications = () => API.get("/applications");
export const singleapplication = (id) => API.get(`/application/${id}`);
export const updateapplication = (id, isView) =>
  API.put(`/application/${id}`, { isView: isView });
export const deleteapplication = (id) => API.delete(`/application/${id}`);

// scholarship

export const scholarship = (formData) => API.post("/scholarship", formData);
export const scholarships = () => API.get("/scholarships");
export const singlescholarship = (id) => API.get(`/scholarship/${id}`);
export const updatescholarship = (id, formData) =>
  API.put(`/scholarship/${id}`, formData);
export const scholarshipImageUpdate = (id, formData) =>
  API.post(`/scholarshipFile/${id}`, formData);
export const deletescholarship = (id) => API.delete(`/scholarship/${id}`);

// testimonial

export const testimonial = (formData) => API.post("/testimonial", formData);
export const testimonials = () => API.get("/testimonials");
export const singletestimonial = (id) => API.get(`/testimonial/${id}`);
export const updatetestimonial = (id, formData) =>
  API.put(`/testimonial/${id}`, formData);
export const testimonialImageUpdate = (id, formData) =>
  API.post(`/testimonialFile/${id}`, formData);
export const deletetestimonial = (id) => API.delete(`/testimonial/${id}`);

// notice

export const notice = (formData) => API.post("/notice", formData);
export const notices = () => API.get("/notices");
export const singlenotice = (id) => API.get(`/notice/${id}`);
export const updatenotice = (id, formData) =>
  API.put(`/notice/${id}`, formData);
export const noticeImageUpdate = (id, formData) =>
  API.post(`/noticeFile/${id}`, formData);
export const deletenotice = (id) => API.delete(`/notice/${id}`);

// partner

export const partner = (formData) => API.post("/partner", formData);
export const partners = () => API.get("/partners");
export const singlepartner = (id) => API.get(`/partner/${id}`);
export const updatepartner = (id, formData) =>
  API.put(`/partner/${id}`, formData);
export const partnerImageUpdate = (id, formData) =>
  API.post(`/partnerFile/${id}`, formData);
export const deletepartner = (id) => API.delete(`/partner/${id}`);

// semester
export const createSemester = (formData) => API.post("/semester", formData);
export const getSemesters = () => API.get("/semesters");
export const getSemester = () => API.get("/semester/:id");
export const updateSemester = (formData) => API.put("/semester/:id", formData);
export const deleteSemester = () => API.delete("/semester/:id");

// gallery

export const gallery = (formData) => API.post("/gallery", formData);
export const gallerys = () => API.get("/gallerys");
export const singleGallery = (id) => API.get(`/gallery/${id}`);
export const galleryUpdate = (id, formData) =>
  API.put(`/gallery/${id}`, formData);
export const deleteGalleryImage = (id, index) =>
  API.post(`/gallery-image-delete/${id}`, { index: index });
export const updateGalleryImage = (id, formData) =>
  API.post(`/gallery-image-update/${id}`, formData);
export const deleteGallery = (id) => API.delete(`/gallery/${id}`);
