import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getGallery,
  resetError,
  updateGallery,
} from "../../../../redux/features/gallerySlice";
import "../admindash.css";

const UpdateGallery = () => {
  const [states, setState] = useState({
    title: "",
  });

  const { error, loading, gallery } = useSelector((state) => state.gallery);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (!loading) {
      setState({
        title: gallery.title || "",
      });
    }
  }, [loading, gallery]);

  const handleInput = (e) => {
    setState({
      ...states,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (params.id) {
      dispatch(getGallery(params.id));
    }
  }, [dispatch, params.id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateGallery({
        id: params.id,
        formData: {
          title: states.title,
        },
        navigate,
        toast,
      })
    );
  };

  useEffect(() => {
    error && toast.error(error);
    dispatch(resetError());
  }, [error, dispatch]);

  return (
    <div className="inner_form">
      <div className="container pt-lg-5 pb-lg-5">
        <div className="create_form p-5">
          <h2 className="text-center">Update Gallery Title</h2>
          <form onSubmit={handleSubmit}>
            <div className="form_control">
              <label>Title</label>
              <input
                type="text"
                id="title"
                name="title"
                value={states.title}
                onChange={handleInput}
              />
            </div>
            <br /> <br />
            <div className="mt-4">
              <button>Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateGallery;
