import PropagateLoader from "react-spinners/PropagateLoader";
import "./style.css";

const Loading = () => {
  return (
    <div className="text-center loader">
      <PropagateLoader color="#8a1204" />
    </div>
  );
};

export default Loading;
