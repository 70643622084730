import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const createPartner = createAsyncThunk(
  "partner/createPartner",
  async ({ formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.partner(formData);
      toast.success("Partner added Successfully");
      navigate("/admin/partners");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPartners = createAsyncThunk(
  "partner/getPartners",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.partners();

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPartner = createAsyncThunk(
  "partner/getPartner",
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.singlepartner(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updatePartner = createAsyncThunk(
  "partner/updatePartner",
  async ({ id, formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.updatepartner(id, formData);
      toast.success("Partner updated Successfully");
      navigate("/admin/partners");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updatePartnerImage = createAsyncThunk(
  "partner/updatePartnerImage",
  async ({ id, formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.partnerImageUpdate(id, formData);
      toast.success("Partner image updated Successfully");
      navigate("/admin/partners");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deletePartner = createAsyncThunk(
  "partner/deletePartner",
  async ({ id, toast }, { rejectWithValue }) => {
    try {
      const response = await api.deletepartner(id);
      toast.success("Partner deleted Successfully");
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

const partnerSlice = createSlice({
  name: "partner",
  initialState: {
    partner: {},
    partners: [],
    error: "",
    loading: false,
  },
  reducers: {
    resetError: (state, action) => {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPartner.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(createPartner.fulfilled, (state, action) => {
        state.loading = false;
        state.partners = action.payload;
      })
      .addCase(createPartner.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(getPartners.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getPartners.fulfilled, (state, action) => {
        state.loading = false;
        state.partners = action.payload;
      })
      .addCase(getPartners.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(getPartner.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getPartner.fulfilled, (state, action) => {
        state.loading = false;
        state.partner = action.payload;
      })
      .addCase(getPartner.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(updatePartner.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePartner.fulfilled, (state, action) => {
        state.loading = false;
        state.partner = action.payload;
      })
      .addCase(updatePartner.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(updatePartnerImage.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePartnerImage.fulfilled, (state, action) => {
        state.loading = false;
        state.partner = action.payload;
      })
      .addCase(updatePartnerImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(deletePartner.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(deletePartner.fulfilled, (state, action) => {
        state.loading = false;
        const {
          arg: { id },
        } = action.meta;
        if (id) {
          state.partners = state.partners.filter((ac) => ac._id !== id);
        }
      })
      .addCase(deletePartner.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message;
      });
  },
});

export const { resetError } = partnerSlice.actions;
export default partnerSlice.reducer;
