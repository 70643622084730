import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./features/authSlice";
import BannerReducer from "./features/bannerSlice";
import StudentReducer from "./features/studentSlice";
import TeacherReducer from "./features/teacherSlice";
import FacultyReducer from "./features/facultySlice";
import SemesterReducer from "./features/semesterSlice";
import AboutReducer from "./features/aboutSlice";
import PmessageReducer from "./features/pmessageSlice";
import AdmissionReducer from "./features/admissionSlice";
import NewsReducer from "./features/newsSlice";
import FaqReducer from "./features/faqSlice";
import ContactReducer from "./features/contactSlice";
import ApplicationReducer from "./features/applicationSlice";
import ScholarshipReducer from "./features/scholarshipSlice";
import TestimonialReducer from "./features/testimonialSlice";
import GalleryReducer from "./features/gallerySlice";
import PartnerReducer from "./features/partnerSlice";
import NoticeReducer from "./features/noticeSlice";

export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    banner: BannerReducer,
    student: StudentReducer,
    teacher: TeacherReducer,
    faculty: FacultyReducer,
    semester: SemesterReducer,
    about: AboutReducer,
    pmessage: PmessageReducer,
    admission: AdmissionReducer,
    news: NewsReducer,
    faq: FaqReducer,
    contact: ContactReducer,
    application: ApplicationReducer,
    scholarship: ScholarshipReducer,
    testimonial: TestimonialReducer,
    gallery: GalleryReducer,
    partner: PartnerReducer,
    notice: NoticeReducer,
  },
});
