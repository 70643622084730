import { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  createGallery,
  resetError,
} from "../../../../redux/features/gallerySlice";
import "../admindash.css";

const AddGallery = () => {
  const [states, setState] = useState({
    title: "",
    image: [],
  });

  const [imagePreviews, setImagePreviews] = useState([]);

  const { error } = useSelector((state) => state.gallery);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fileHandle = (e) => {
    if (e.target.files.length !== 0) {
      const selectedImages = Array.from(e.target.files);

      setState({
        ...states,
        image: [...states.image, ...selectedImages], // Update the array with new images
      });

      const previews = [...imagePreviews];
      selectedImages.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          previews.push(reader.result);
          setImagePreviews([...previews]);
        };
        reader.readAsDataURL(file);
      });
    }
  };

  const handleInput = (e) => {
    setState({
      ...states,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const { title, image } = states;
    const formData = new FormData();
    formData.append("title", title);
    image.forEach((image, index) => {
      formData.append("image", image); // Use the same key for each image
    });
    dispatch(createGallery({ formData, navigate, toast }));
  };

  const removeImage = (index) => {
    const updatedImages = [...states.image];
    updatedImages.splice(index, 1);

    setState({
      ...states,
      image: updatedImages,
    });

    setImagePreviews(updatedImages.map((file) => URL.createObjectURL(file)));
  };

  useEffect(() => {
    error && toast.error(error);
    dispatch(resetError());
  }, [error, dispatch]);
  return (
    <div className="inner_form">
      <div className="container pt-lg-5 pb-lg-5">
        <div className="create_form p-5">
          <h2 className="text-center">Create Gallery</h2>
          <form onSubmit={handleSubmit}>
            <div className="form_control">
              <label>Title</label>
              <input
                type="text"
                id="title"
                name="title"
                value={states.title}
                onChange={handleInput}
                required
              />
            </div>

            <div className="form_control">
              <label>Add Image</label>
              <input
                type="file"
                multiple
                id="image"
                name="image"
                onChange={fileHandle}
                required
              />
            </div>
            <div
              className="image_preview"
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              {imagePreviews.map((preview, index) => (
                <div
                  key={index}
                  className="image_perv mt-3"
                  style={{ width: "200px" }}
                >
                  <img
                    className="w-100"
                    src={preview}
                    height={120}
                    alt={`Preview_${index}`}
                    style={{
                      border: "2px solid #fff",
                    }}
                  />
                  <IoMdClose
                    onClick={() => removeImage(index)}
                    className="close_icon"
                  />
                </div>
              ))}
            </div>
            <div className="mt-4">
              <button>Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddGallery;
