import React, { useState, useEffect } from "react";
import "../admindash.css";
import AdminNav from "../AdminNav";
import AdminTopNav from "../AdminTopNav";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Localsearch from "../../../../components/forms/Localsearch";
import { FaPen } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";
import { getSemesters } from "../../../../redux/features/semesterSlice";

const AllSemester = () => {
  const [keyword, setKeyword] = useState("");

  const { user } = useSelector((state) => state.auth);
  const { semesters } = useSelector((state) => state.semester);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSemesters());
  }, [dispatch]);

  const searched = (keyword) => (f) => f.title.toLowerCase().includes(keyword);

  return (
    <div className="dash">
      <>
        <div id="mySidenav" className="sidenav">
          <AdminNav />
        </div>
        <div id="main">
          <AdminTopNav user={user} />
          <div className="tables mt-5">
            <div className="filter my-2 ">
              <div className="filt">
                <Localsearch keyword={keyword} setKeyword={setKeyword} />
              </div>
              <div className="add">
                <Link to="/admin/add-semester">
                  <button>Add</button>
                </Link>
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>S.N</th>
                  <th>Faculty</th>
                  <th>Semester</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {semesters &&
                  semesters.length > 0 &&
                  semesters.filter(searched(keyword)).map((s, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{s.faculty?.title}</td>
                      <td>{s.title}</td>
                      <td>
                        <span>
                          <FaPen />
                        </span>
                        &nbsp; &nbsp; &nbsp;
                        <span>
                          <AiOutlineDelete color="red" />
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    </div>
  );
};

export default AllSemester;
