import React from "react";
import "./admindash.css";
import { useDispatch } from "react-redux";
import { setLogout } from "../../../redux/features/authSlice";
import { useNavigate } from "react-router-dom";

const AdminTopNav = ({ user }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(setLogout());
    navigate("/");
  };
  return (
    <div className="dash_head">
      <div className="col-div-6">
        <span
          style={{ fontSize: 30, cursor: "pointer", color: "white" }}
          className="nav"
        >
          ☰ Dashboard
        </span>
      </div>

      <div className="col-div-6">
        <div className="logout_button">
          <button onClick={() => handleLogout()}>Logout</button>
        </div>
      </div>
      <div className="clearfix" />
    </div>
  );
};

export default AdminTopNav;
