import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const teacherLogin = createAsyncThunk(
  "teacher/teacherLogin",
  async ({ state, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.teacherSingnIn(state);
      toast.success("Login Successfully");
      navigate("/teacher/dashboard");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error);
    }
  }
);

const teacherSlice = createSlice({
  name: "teacher",
  initialState: {
    teacher: null,
    error: "",
    loading: false,
  },
  reducers: {
    setTeacher: (state, action) => {
      state.teacher = action.payload;
    },
    setLogout: (state, action) => {
      localStorage.clear();
      state.teacher = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(teacherLogin.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(teacherLogin.fulfilled, (state, action) => {
        state.loading = false;
        localStorage.setItem("profile", JSON.stringify({ ...action.payload }));
        state.teacher = action.payload;
      })
      .addCase(teacherLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });
  },
});

export const { setTeacher, setLogout } = teacherSlice.actions;

export default teacherSlice.reducer;
