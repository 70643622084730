import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "../admindash.css";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "../Quil";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";

import { createNews, resetError } from "../../../../redux/features/newsSlice";

const AddNews = () => {
  const [states, setState] = useState({
    title: "",
    image: [],
  });
  const [description, setDescription] = useState("");
  const [currentImage, setCurrentImage] = useState("Choose Image");
  const [imagePreview, setImagePreview] = useState();

  const { error } = useSelector((state) => state.news);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const flieHandle = (e) => {
    if (e.target.files.length !== 0) {
      setCurrentImage(e.target.files[0].name);
      setState({
        ...states,
        [e.target.name]: e.target.files[0],
      });
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleInput = (e) => {
    setState({
      ...states,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const { title, image } = states;
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("image", image);
    dispatch(createNews({ formData, navigate, toast }));
  };
  useEffect(() => {
    error && toast.error(error);
    dispatch(resetError());
  }, [error, dispatch]);
  return (
    <div className="inner_form">
      <div className="container pt-lg-5 pb-lg-5">
        <div className="create_form p-5">
          <h2 className="text-center">Create News and Events</h2>
          <form onSubmit={handleSubmit}>
            <div className="form_control">
              <label>Title</label>
              <input
                type="text"
                id="title"
                name="title"
                value={states.title}
                onChange={handleInput}
              />
            </div>
            <div className="form_control">
              <label>Description</label>
              <EditorToolbar toolbarId={"t1"} />
              <ReactQuill
                theme="snow"
                value={description}
                onChange={setDescription}
                placeholder={"Write some descriptions..."}
                modules={modules("t1")}
                formats={formats}
                style={{ height: "300px" }}
              />
            </div>
            <br /> <br />
            <div className="form_control">
              <label>Add Image</label>
              <input
                type="file"
                id="image"
                name="image"
                onChange={flieHandle}
              />
            </div>
            <div className="image_preview">
              {imagePreview ? (
                <img src={imagePreview} alt="_image" className="w-100" />
              ) : (
                <img src={currentImage} alt="_image" className="w-100" />
              )}
            </div>
            <div className="mt-4">
              <button>Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddNews;
