import React, { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { FaPen } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Localsearch from "../../../../components/forms/Localsearch";
import AdminNav from "../AdminNav";
import AdminTopNav from "../AdminTopNav";
import { FcGallery } from "react-icons/fc";
import { toast } from "react-toastify";
import "../admindash.css";
import {
  deleteTestimonial,
  getTestimonials,
} from "../../../../redux/features/testimonialSlice";

const AllTestimonials = () => {
  const [keyword, setKeyword] = useState("");
  const { user } = useSelector((state) => state.auth);
  const { testimonials } = useSelector((state) => state.testimonial);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTestimonials());
  }, [dispatch]);

  const searched = (keyword) => (f) => f.name.toLowerCase().includes(keyword);

  const handleDelete = (id) => {
    const confirm = window.confirm(
      "Are you really want to delete this banner?"
    );
    if (confirm) {
      dispatch(deleteTestimonial({ id, toast }));
    }
  };

  return (
    <div className="dash">
      <>
        <div id="mySidenav" className="sidenav">
          <AdminNav />
        </div>
        <div id="main">
          <AdminTopNav user={user} />
          <div className="tables mt-5">
            <div className="filter my-2 ">
              <div className="filt">
                <Localsearch keyword={keyword} setKeyword={setKeyword} />
              </div>
              <div className="add">
                <Link to="/admin/add-testimonial">
                  <button>Add</button>
                </Link>
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>S.N</th>
                  <th>Name</th>
                  <th>Faculty</th>
                  <th>Semester</th>
                  <th>Image</th>
                  <th style={{ width: "120px" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {testimonials &&
                  testimonials.length > 0 &&
                  testimonials.filter(searched(keyword))?.map((b, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{b.name}</td>
                      <td>{b.faculty}</td>
                      <td>{b.semester}</td>
                      <td>
                        <img
                          src={`${process.env.REACT_APP_API_IMAGE_URL}${b.image}`}
                          alt="banner_image"
                          height={80}
                        />
                      </td>
                      <td>
                        <Link
                          to={`/admin/update-testimonial-image/${b._id}`}
                          className="text-white"
                        >
                          <span>
                            <FcGallery />
                          </span>
                        </Link>
                        &nbsp; &nbsp; &nbsp;
                        <Link
                          to={`/admin/update-testimonial/${b._id}`}
                          className="text-white"
                        >
                          <span>
                            <FaPen />
                          </span>
                        </Link>
                        &nbsp; &nbsp; &nbsp;
                        <span
                          onClick={() => handleDelete(b._id)}
                          className="delete"
                        >
                          <AiOutlineDelete color="red" />
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    </div>
  );
};

export default AllTestimonials;
