import React, { useState, useEffect } from "react";
import { MdMenuOpen } from "react-icons/md";
import { Drawer } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getFaculties } from "../../redux/features/facultySlice";

const Drawers = () => {
  const { faculties } = useSelector((state) => state.faculty);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFaculties());
  }, [dispatch]);

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div>
        <MdMenuOpen className="menu_icon" onClick={showDrawer} />
      </div>

      <Drawer
        title={
          <div className="button drawer">
            <Link
              to="/apply-online"
              className="text-decoration-none"
              onClick={onClose}
            >
              <button className="slide_from_left">Apply Now</button>
            </Link>
          </div>
        }
        onClose={onClose}
        open={open}
      >
        <ul className="drawer">
          <Link to="/" className="text-decoration-none" onClick={onClose}>
            <li className="nav_link">Home</li>
          </Link>
          <Link
            to="/notices"
            className="text-decoration-none"
            onClick={onClose}
          >
            <li className="nav_link">Notice</li>
          </Link>
          <Link
            to="/gallery"
            className="text-decoration-none"
            onClick={onClose}
          >
            <li className="nav_link">Gallery</li>
          </Link>
          <li className="nav_link">Alumini</li>

          <Link
            to="who-we-are"
            className="text-decoration-none text-white"
            onClick={onClose}
          >
            <li className="nav_link">Who We Are ?</li>
          </Link>

          <li className="nav_link">
            Academics
            <ul className="dropdown">
              {faculties &&
                faculties.length > 0 &&
                faculties.map((f) => (
                  <li key={f._id} onClick={onClose}>
                    <Link
                      to={`/academics/${f.slug}/${f._id}`}
                      className="text-decoration-none text-white"
                    >
                      {f.title}
                    </Link>
                  </li>
                ))}
            </ul>
          </li>
          <Link
            to="/admissions"
            className="text-decoration-none"
            onClick={onClose}
          >
            <li className="nav_link">Admissions</li>
          </Link>
          <Link
            to="/scholarships"
            className="text-decoration-none"
            onClick={onClose}
          >
            <li className="nav_link">Scholarships</li>
          </Link>
          <Link to="/why-us" className="text-decoration-none" onClick={onClose}>
            <li className="nav_link">Why Us ?</li>
          </Link>
          <Link
            to="/news-and-events"
            className="text-decoration-none"
            onClick={onClose}
          >
            <li className="nav_link">News & Events</li>
          </Link>
          <Link to="/faqs" className="text-decoration-none" onClick={onClose}>
            <li className="nav_link">FAQ's</li>
          </Link>
          <Link
            to="/contact-us"
            className="text-decoration-none"
            onClick={onClose}
          >
            <li className="nav_link">Contact</li>
          </Link>
        </ul>
      </Drawer>
    </>
  );
};
export default Drawers;
