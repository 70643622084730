import { Navigate, Outlet } from "react-router-dom";

const TeacherRoute = () => {
  const teacher = JSON.parse(localStorage.getItem("profile"));
  return teacher && teacher?.teacher.role === "teacher" ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  );
};
export default TeacherRoute;
