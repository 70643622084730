import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const createFaculty = createAsyncThunk(
  "faculty/createFaculty",
  async ({ formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.faculty(formData);
      toast.success("Faculty added Successfully");
      navigate("/admin/faculties");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFaculties = createAsyncThunk(
  "faculty/getFaculites",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.faculties();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFaculty = createAsyncThunk(
  "faculty/getFaculty",
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.singleFaculty(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateFaculty = createAsyncThunk(
  "faculty/updateFaculty",
  async ({ id, formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.updateFaculty(id, formData);
      toast.success("Faculty updated Successfully");
      navigate("/admin/faculties");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateFacultyImage = createAsyncThunk(
  "faculty/updateFacultyImage",
  async ({ id, formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.facultyImageUpdate(id, formData);
      toast.success("Faculty image updated Successfully");
      navigate("/admin/faculties");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteFaculty = createAsyncThunk(
  "faculty/deleteFaculty",
  async ({ id, toast }, { rejectWithValue }) => {
    try {
      const response = await api.deleteFaculty(id);
      toast.success("Faculty deleted Successfully");
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

const facultySlice = createSlice({
  name: "faculty",
  initialState: {
    faculty: {},
    faculties: [],
    error: "",
    loading: false,
  },
  reducers: {
    resetError: (state, action) => {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createFaculty.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(createFaculty.fulfilled, (state, action) => {
        state.loading = false;
        state.faculties = action.payload;
      })
      .addCase(createFaculty.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(getFaculties.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFaculties.fulfilled, (state, action) => {
        state.loading = false;
        state.faculties = action.payload;
      })
      .addCase(getFaculties.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(getFaculty.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFaculty.fulfilled, (state, action) => {
        state.loading = false;
        state.faculty = action.payload;
      })
      .addCase(getFaculty.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(updateFaculty.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateFaculty.fulfilled, (state, action) => {
        state.loading = false;
        state.faculty = action.payload;
      })
      .addCase(updateFaculty.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(updateFacultyImage.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateFacultyImage.fulfilled, (state, action) => {
        state.loading = false;
        state.faculty = action.payload;
      })
      .addCase(updateFacultyImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(deleteFaculty.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(deleteFaculty.fulfilled, (state, action) => {
        state.loading = false;
        const {
          arg: { id },
        } = action.meta;
        if (id) {
          state.faculties = state.faculties.filter((ac) => ac._id !== id);
        }
      })
      .addCase(deleteFaculty.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message;
      });
  },
});

export const { resetError } = facultySlice.actions;
export default facultySlice.reducer;
