const Localsearch = ({ keyword, setKeyword }) => {
  const handleChange = (e) => {
    e.preventDefault();
    setKeyword(e.target.value.toLowerCase());
  };
  return (
    <div className="container p-2">
      <input
        type="search"
        placeholder="Filter"
        value={keyword}
        onChange={handleChange}
        className="form-control mb-4"
      ></input>
    </div>
  );
};
export default Localsearch;
