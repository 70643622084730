import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "../admindash.css";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "../Quil";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  getNews,
  resetError,
  updateNews,
} from "../../../../redux/features/newsSlice";

const UpdateNews = () => {
  const [states, setState] = useState({
    title: "",
  });
  const [description, setDescription] = useState("");

  const { error, loading, news } = useSelector((state) => state.news);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (!loading) {
      setState({
        title: news.title || "",
      });
      setDescription(news.description);
    }
  }, [loading, news]);

  const handleInput = (e) => {
    setState({
      ...states,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (params.id) {
      dispatch(getNews(params.id));
    }
  }, [dispatch, params.id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateNews({
        id: params.id,
        formData: {
          title: states.title,
          description: description,
        },
        navigate,
        toast,
      })
    );
  };

  useEffect(() => {
    error && toast.error(error);
    dispatch(resetError());
  }, [error, dispatch]);
  return (
    <div className="inner_form">
      <div className="container pt-lg-5 pb-lg-5">
        <div className="create_form p-5">
          <h2 className="text-center">Update News Content</h2>
          <form onSubmit={handleSubmit}>
            <div className="form_control">
              <label>Title</label>
              <input
                type="text"
                id="title"
                name="title"
                value={states.title}
                onChange={handleInput}
              />
            </div>
            <div className="form_control">
              <label>Description</label>
              <EditorToolbar toolbarId={"t1"} />
              <ReactQuill
                theme="snow"
                value={description}
                onChange={setDescription}
                placeholder={"Write some descriptions..."}
                modules={modules("t1")}
                formats={formats}
                style={{ height: "300px" }}
              />
            </div>
            <br /> <br />
            <div className="mt-4">
              <button>Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateNews;
