import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const createPmessage = createAsyncThunk(
  "pmessage/createPmessage",
  async ({ formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.pmessage(formData);
      toast.success("Principle message added Successfully");
      navigate("/admin/pmessages");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPmessages = createAsyncThunk(
  "pmessage/getPmessages",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.pmessages();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPmessage = createAsyncThunk(
  "pmessage/getPmessage",
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.singlePmessage(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updatePmessage = createAsyncThunk(
  "pmessage/updatePmessage",
  async ({ id, formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.updatePmessage(id, formData);
      toast.success("Principle message updated Successfully");
      navigate("/admin/pmessages");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updatePmessageImage = createAsyncThunk(
  "pmessage/updatePmessageImage",
  async ({ id, formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.pmessageImageUpdate(id, formData);
      toast.success("Principle message image updated Successfully");
      navigate("/admin/pmessages");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deletePmessage = createAsyncThunk(
  "pmessage/deletePmessage",
  async ({ id, toast }, { rejectWithValue }) => {
    try {
      const response = await api.deletePmessage(id);
      toast.success("Principle message deleted Successfully");
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

const pmessageSlice = createSlice({
  name: "pmessage",
  initialState: {
    pmessage: {},
    pmessages: [],
    error: "",
    loading: false,
  },
  reducers: {
    resetError: (state, action) => {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPmessage.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(createPmessage.fulfilled, (state, action) => {
        state.loading = false;
        state.pmessages = action.payload;
      })
      .addCase(createPmessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(getPmessages.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPmessages.fulfilled, (state, action) => {
        state.loading = false;
        state.pmessages = action.payload;
      })
      .addCase(getPmessages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(getPmessage.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPmessage.fulfilled, (state, action) => {
        state.loading = false;
        state.pmessage = action.payload;
      })
      .addCase(getPmessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(updatePmessage.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePmessage.fulfilled, (state, action) => {
        state.loading = false;
        state.pmessage = action.payload;
      })
      .addCase(updatePmessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(updatePmessageImage.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePmessageImage.fulfilled, (state, action) => {
        state.loading = false;
        state.pmessage = action.payload;
      })
      .addCase(updatePmessageImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(deletePmessage.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(deletePmessage.fulfilled, (state, action) => {
        state.loading = false;
        const {
          arg: { id },
        } = action.meta;
        if (id) {
          state.pmessages = state.pmessages.filter((ac) => ac._id !== id);
        }
      })
      .addCase(deletePmessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message;
      });
  },
});

export const { resetError } = pmessageSlice.actions;
export default pmessageSlice.reducer;
