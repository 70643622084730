import { useEffect } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import Drawers from "./Drawers";
import logo from "../../img/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { getFaculties } from "../../redux/features/facultySlice";

const HeaderSecond = () => {
  const { faculties } = useSelector((state) => state.faculty);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFaculties());
  }, [dispatch]);
  return (
    <div className="second_header navbars">
      <div className="navs">
        <div className="left_nav">
          <ul>
            <li className="nav_link">
              <Link to="/who-we-are" className="text-decoration-none text-dark">
                Who We Are?
              </Link>
            </li>
            <li className="nav_link">
              Academics
              <ul className="dropdown pt-2 pb-2">
                {faculties &&
                  faculties.length > 0 &&
                  faculties.map((f) => (
                    <Link
                      to={`/academics/${f.slug}/${f._id}`}
                      className="text-decoration-none text-white"
                      key={f._id}
                    >
                      <li>{f.title}</li>
                    </Link>
                  ))}
              </ul>
            </li>
            <li className="nav_link">
              <Link to="/admissions" className="text-decoration-none text-dark">
                Admissions
              </Link>
            </li>
            <li className="nav_link">
              <Link
                to="/scholarships"
                className="text-decoration-none text-dark"
              >
                Scholarships
              </Link>
            </li>
          </ul>
        </div>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className="right_nav">
          <ul>
            <li className="nav_link">
              <Link to="/why-us" className="text-decoration-none text-dark">
                Why Us ?
              </Link>
            </li>
            {/* <li></li> */}
            <li className="nav_link">
              <Link
                to="/news-and-events"
                className="text-decoration-none text-dark"
              >
                News & Events
              </Link>
            </li>
            <li className="nav_link">
              <Link to="/faqs" className="text-decoration-none text-dark">
                FAQ's
              </Link>
            </li>
            <li className="nav_link">
              <Link to="/contact-us" className="text-decoration-none text-dark">
                Contact
              </Link>
            </li>
          </ul>
        </div>
        <div className="button header_button">
          <Link to="/apply-online">
            <button className="slide_from_left">Apply Now</button>
          </Link>
        </div>
        <div className="open_menu">
          <Drawers />
        </div>
      </div>
    </div>
  );
};

export default HeaderSecond;
