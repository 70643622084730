import React, { useState, useEffect } from "react";
import "../admindash.css";
import AdminNav from "../AdminNav";
import AdminTopNav from "../AdminTopNav";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Localsearch from "../../../../components/forms/Localsearch";
import { FaPen } from "react-icons/fa";
import { toast } from "react-toastify";
import { AiOutlineDelete } from "react-icons/ai";
import { FcGallery } from "react-icons/fc";
import {
  deleteAdmission,
  getAdmissions,
} from "../../../../redux/features/admissionSlice";

const AdmissionContent = () => {
  const [keyword, setKeyword] = useState("");
  const { user } = useSelector((state) => state.auth);
  const { admissions } = useSelector((state) => state.admission);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAdmissions());
  }, [dispatch]);

  const searched = (keyword) => (f) => f.title.toLowerCase().includes(keyword);

  const handleDelete = (id) => {
    const confirm = window.confirm(
      "Are you really want to delete this content?"
    );
    if (confirm) {
      dispatch(deleteAdmission({ id, toast }));
    }
  };

  return (
    <div className="dash">
      <>
        <div id="mySidenav" className="sidenav">
          <AdminNav />
        </div>
        <div id="main">
          <AdminTopNav user={user} />
          <div className="tables mt-5">
            <div className="filter my-2 ">
              <div className="filt">
                <Localsearch keyword={keyword} setKeyword={setKeyword} />
              </div>
              <div className="add">
                <Link to="/admin/add-admission">
                  <button>Add</button>
                </Link>
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>S.N</th>
                  <th>Title</th>
                  <th>Image</th>
                  <th style={{ width: "120px" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {admissions &&
                  admissions.length > 0 &&
                  admissions.filter(searched(keyword)).map((p, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{p.title}</td>
                      <td>
                        <img
                          src={`${process.env.REACT_APP_API_IMAGE_URL}${p.image}`}
                          alt="faculty_image"
                          height={80}
                        />
                      </td>
                      <td>
                        <Link
                          to={`/admin/update-admission-image/${p._id}`}
                          className="text-white"
                        >
                          <span>
                            <FcGallery />
                          </span>
                        </Link>
                        &nbsp; &nbsp; &nbsp;
                        <Link
                          to={`/admin/update-admission/${p._id}`}
                          className="text-white"
                        >
                          <span>
                            <FaPen />
                          </span>
                        </Link>
                        &nbsp; &nbsp; &nbsp;
                        <span
                          onClick={() => handleDelete(p._id)}
                          className="delete"
                        >
                          <AiOutlineDelete color="red" />
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    </div>
  );
};

export default AdmissionContent;
