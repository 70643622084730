import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getBanner,
  resetError,
  updateBanner,
} from "../../../../redux/features/bannerSlice";
import "../admindash.css";
const UpdateBanner = () => {
  const [states, setState] = useState({
    title: "",
    shortText: "",
    shortDescription: "",
  });

  const { error, loading, banner } = useSelector((state) => state.banner);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (!loading) {
      setState({
        title: banner.title || "",
        shortText: banner.shortText || "",
        shortDescription: banner.shortDescription || "",
      });
    }
  }, [loading, banner]);

  const handleInput = (e) => {
    setState({
      ...states,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (params.id) {
      dispatch(getBanner(params.id));
    }
  }, [dispatch, params.id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateBanner({
        id: params.id,
        formData: {
          title: states.title,
          shortText: states.shortText,
          shortDescription: states.shortDescription,
        },
        navigate,
        toast,
      })
    );
  };
  useEffect(() => {
    error && toast.error(error);
    dispatch(resetError());
  }, [error, dispatch]);
  return (
    <div className="inner_form">
      <div className="container pt-lg-5 pb-lg-5">
        <div className="create_form p-5">
          <h2 className="text-center">Update Banner</h2>
          <form onSubmit={handleSubmit}>
            <div className="form_control">
              <label>Title</label>
              <input
                type="text"
                id="title"
                name="title"
                value={states.title}
                onChange={handleInput}
              />
            </div>
            <div className="form_control">
              <label>Short Text</label>
              <input
                type="text"
                id="shortText"
                name="shortText"
                value={states.shortText}
                onChange={handleInput}
              />
            </div>
            <div className="form_control">
              <label>Short Description</label>
              <textarea
                cols={100}
                rows={5}
                type="text"
                id="shortDescription"
                name="shortDescription"
                value={states.shortDescription}
                onChange={handleInput}
              />
            </div>

            <div className="mt-4">
              <button>Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateBanner;
