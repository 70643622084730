import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const studentLogin = createAsyncThunk(
  "student/studentLogin",
  async ({ state, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.studentSingnIn(state);
      toast.success("Login Successfully");
      navigate("/student/dashboard");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error);
    }
  }
);
export const studentFacultyAndSubjects = createAsyncThunk(
  "student/details/:id",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.studentFacultyAndSubjects();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const studentSlice = createSlice({
  name: "student",
  initialState: {
    student: null,
    error: "",
    loading: false,
    faculty_semesters: [],
  },
  reducers: {
    setStudent: (state, action) => {
      state.student = action.payload;
    },
    setLogout: (state, action) => {
      localStorage.clear();
      state.student = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(studentLogin.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(studentLogin.fulfilled, (state, action) => {
        state.loading = false;
        localStorage.setItem("profile", JSON.stringify({ ...action.payload }));
        state.student = action.payload;
      })
      .addCase(studentLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(studentFacultyAndSubjects.pending, (state) => {
        state.loading = true;
      })
      .addCase(studentFacultyAndSubjects.fulfilled, (state, action) => {
        state.loading = false;
        state.faculty_semesters = action.payload;
      })
      .addCase(studentFacultyAndSubjects.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });
  },
});

export const { setStudent, setLogout } = studentSlice.actions;

export default studentSlice.reducer;
