import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const createFaq = createAsyncThunk(
  "faq/createFaq",
  async ({ formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.faq(formData);
      toast.success("Faq added Successfully!!");
      navigate("/admin/faqs");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFaqs = createAsyncThunk(
  "faq/getFaqs",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.faqs();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFaq = createAsyncThunk(
  "faq/getFaq",
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.singleFaq(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateFaq = createAsyncThunk(
  "faq/updateFaq",
  async ({ id, formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.updateFaq(id, formData);
      toast.success("Faq updated Successfully");
      navigate("/admin/faqs");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateFaqImage = createAsyncThunk(
  "faq/updateFaqImage",
  async ({ id, formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.faqImageUpdate(id, formData);
      toast.success("Faq image updated Successfully");
      navigate("/admin/faqs");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteFaq = createAsyncThunk(
  "faq/deleteFaq",
  async ({ id, toast }, { rejectWithValue }) => {
    try {
      const response = await api.deleteFaq(id);
      toast.success("Faq deleted Successfully");
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

const faqSlice = createSlice({
  name: "faq",
  initialState: {
    faq: {},
    faqs: [],
    error: "",
    loading: false,
  },
  reducers: {
    resetError: (state, action) => {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createFaq.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(createFaq.fulfilled, (state, action) => {
        state.loading = false;
        state.faqs = action.payload;
      })
      .addCase(createFaq.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(getFaqs.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFaqs.fulfilled, (state, action) => {
        state.loading = false;
        state.faqs = action.payload;
      })
      .addCase(getFaqs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(getFaq.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFaq.fulfilled, (state, action) => {
        state.loading = false;
        state.faq = action.payload;
      })
      .addCase(getFaq.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(updateFaq.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateFaq.fulfilled, (state, action) => {
        state.loading = false;
        state.faq = action.payload;
      })
      .addCase(updateFaq.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(updateFaqImage.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateFaqImage.fulfilled, (state, action) => {
        state.loading = false;
        state.faq = action.payload;
      })
      .addCase(updateFaqImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(deleteFaq.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(deleteFaq.fulfilled, (state, action) => {
        state.loading = false;
        const {
          arg: { id },
        } = action.meta;
        if (id) {
          state.faqs = state.faqs.filter((ac) => ac._id !== id);
        }
      })
      .addCase(deleteFaq.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message;
      });
  },
});

export const { resetError } = faqSlice.actions;
export default faqSlice.reducer;
