import parse from "html-react-parser";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import noticeimg from "../../img/notice.jpg";
import { getNews, getNewss } from "../../redux/features/newsSlice";
import "../style.css";

const NewsDetails = () => {
  const { newss, news } = useSelector((state) => state.news);
  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    if (params.id) {
      dispatch(getNews(params.id));
      dispatch(getNewss());
    }
  }, [dispatch, params.id]);

  return (
    <div className="about_page ">
      <div className="inner_head">
        <div className="overlay"></div>
        <p>
          <Link to="/" className="text-decoration-none text-white">
            Home
          </Link>{" "}
          &gt;{" "}
          <Link
            to="/news-and-events"
            className="text-decoration-none text-white"
          >
            News & Events
          </Link>{" "}
          &gt; {params.slug}
        </p>
      </div>
      <div className="about_page_content pt-lg-4 pb-lg-5 pb-3">
        <div className="container pt-3 pt-lg-5">
          <div className="about_left_content">
            <h3>
              <span></span>
              {news.title}
            </h3>

            <div className="news_events">
              <div className="container mt-5">
                <div className="row">
                  <div className="col-lg-7 ps-lg-3 pe-lg-3 description">
                    {parse(`${news.description}`)}
                    <div className="images">
                      <img
                        src={`${
                          news.image
                            ? process.env.REACT_APP_API_IMAGE_URL + news.image
                            : noticeimg
                        }`}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-5 p-1">
                    <div className="latest_news">
                      <div className="latest_head">
                        <h3>Latest News & Events</h3>
                      </div>
                      <div className="pt-3 pb-2">
                        {newss &&
                          newss.length > 0 &&
                          newss.map((n) => (
                            <div key={n._id}>
                              <Link
                                to={`/notice/${n.slug}/${n._id}`}
                                className="text-dark text-decoration-none"
                              >
                                <p>{n.title}</p>
                              </Link>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default NewsDetails;
