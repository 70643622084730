import React, { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Localsearch from "../../../../components/forms/Localsearch";
import AdminNav from "../AdminNav";
import AdminTopNav from "../AdminTopNav";

import { toast } from "react-toastify";
import "../admindash.css";
import {
  deleteContact,
  getContacts,
  updateContact,
} from "../../../../redux/features/contactSlice";

const AllContacts = () => {
  const [keyword, setKeyword] = useState("");

  const { user } = useSelector((state) => state.auth);
  const { contacts } = useSelector((state) => state.contact);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getContacts());
  }, [dispatch]);

  const handleDelete = (id) => {
    const confirm = window.confirm(
      "Are you really want to delete this contact?"
    );
    if (confirm) {
      dispatch(deleteContact({ id, toast }));
    }
  };

  const searched = (keyword) => (f) => f.name.toLowerCase().includes(keyword);

  const updateView = (id) => {
    dispatch(updateContact({ isView: true, id }));
  };

  return (
    <div className="dash">
      <div id="mySidenav" className="sidenav">
        <AdminNav />
      </div>
      <div id="main">
        <AdminTopNav user={user} />
        <div className="tables mt-5">
          <div className="filter my-2 ">
            <div className="filt">
              <Localsearch keyword={keyword} setKeyword={setKeyword} />
            </div>
            <div className="add">
              <Link to="/admin/add-banner">
                <button>Add</button>
              </Link>
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <th>S.N</th>
                <th>Name</th>
                <th>Address</th>
                <th>Phone</th>
                <th>Email</th>
                <th style={{ width: "120px" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {contacts &&
                contacts.length > 0 &&
                contacts.filter(searched(keyword))?.map((b, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{b.name}</td>
                    <td>{b.address}</td>
                    <td>{b.phone}</td>
                    <td>{b.email}</td>

                    <td>
                      &nbsp; &nbsp; &nbsp;
                      <Link
                        to={`/admin/contact/${b._id}`}
                        className="text-white"
                        onClick={() => updateView(b._id)}
                      >
                        {b.isView === false ? (
                          <span>
                            <FaEye />
                          </span>
                        ) : (
                          <span>
                            <FaEyeSlash />
                          </span>
                        )}
                      </Link>
                      &nbsp; &nbsp; &nbsp;
                      <span
                        onClick={() => handleDelete(b._id)}
                        className="delete"
                      >
                        <AiOutlineDelete color="red" />
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AllContacts;
