import { Navigate, Outlet } from "react-router-dom";

const StudentRoute = () => {
  const student = JSON.parse(localStorage.getItem("profile"));
  return student && student?.student.role === "student" ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  );
};
export default StudentRoute;
