import React from "react";
import "./style.css";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaWhatsapp,
  FaTiktok,
} from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="mt-0">
      <div className="footer">
        <div className="college_name">
          <h2>New Summit College</h2>
        </div>
        <div className="line"></div>
        <div className="connect">
          <h3 className="pb-4">Connect with Us</h3>
          <div className="icons">
            <div className="icon">
              <a
                href="https://www.facebook.com/newsummitcollegeNP"
                className="text-white"
                target="__blank"
              >
                <FaFacebookF />
              </a>
            </div>
            <div className="icon">
              <FiInstagram />
            </div>
            <div className="icon">
              <FaLinkedinIn />
            </div>
            <div className="icon">
              <FaWhatsapp />
            </div>
            <div className="icon">
              <FaTiktok />
            </div>
          </div>
        </div>
        <div className="line"></div>
        <div className="links">
          <p>
            <Link to="/" className="flink">
              Home
            </Link>
            <span>/</span>
            <Link to="/notices" className="flink">
              Notice
            </Link>
            <span>/</span>
            <Link to="/faqs" className="flink">
              FAQ'S
            </Link>
            <span>/</span>
            <a
              href="https://bytes-8.netlify.app/"
              className="flink"
              target="__blank"
            >
              Journal-BYTES
            </a>
            <span>/</span>
            <Link to="/gallery" className="flink">
              Gallery
            </Link>
            <span>/</span>
            <Link to="/contact-us" className="flink">
              Contact Us
            </Link>
            <span>/</span> Privacy <span>/</span> Terms
            <span>/</span>{" "}
            <Link to="/news-and-events" className="flink">
              Newsletter
            </Link>
            <span>/</span>
            <Link to="/admin/login" className="flink">
              Login
            </Link>
          </p>
        </div>
      </div>
      <footer>
        &copy;2023 New Summit College <span>|</span> Shantinagar, Kathmandu
      </footer>
    </div>
  );
};

export default Footer;
