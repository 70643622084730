import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const createAbout = createAsyncThunk(
  "about/createAbout",
  async ({ formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.about(formData);
      toast.success("About content added Successfully");
      navigate("/admin/abouts");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAbouts = createAsyncThunk(
  "about/getAbouts",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.abouts();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAbout = createAsyncThunk(
  "about/getAbout",
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.singleAbout(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateAbout = createAsyncThunk(
  "about/updateAbout",
  async ({ id, formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.updateAbout(id, formData);
      toast.success("About content updated Successfully");
      navigate("/admin/abouts");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateAboutImage = createAsyncThunk(
  "about/updateAboutImage",
  async ({ id, formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.aboutImageUpdate(id, formData);
      toast.success("About image updated Successfully");
      navigate("/admin/abouts");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAbout = createAsyncThunk(
  "about/deleteAbout",
  async ({ id, toast }, { rejectWithValue }) => {
    try {
      const response = await api.deleteAbout(id);
      toast.success("About deleted Successfully");
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

const aboutSlice = createSlice({
  name: "about",
  initialState: {
    about: {},
    abouts: [],
    error: "",
    loading: false,
  },
  reducers: {
    resetError: (state, action) => {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAbout.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(createAbout.fulfilled, (state, action) => {
        state.loading = false;
        state.abouts = action.payload;
      })
      .addCase(createAbout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(getAbouts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAbouts.fulfilled, (state, action) => {
        state.loading = false;
        state.abouts = action.payload;
      })
      .addCase(getAbouts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(getAbout.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAbout.fulfilled, (state, action) => {
        state.loading = false;
        state.about = action.payload;
      })
      .addCase(getAbout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(updateAbout.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAbout.fulfilled, (state, action) => {
        state.loading = false;
        state.about = action.payload;
      })
      .addCase(updateAbout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(updateAboutImage.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAboutImage.fulfilled, (state, action) => {
        state.loading = false;
        state.about = action.payload;
      })
      .addCase(updateAboutImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(deleteAbout.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(deleteAbout.fulfilled, (state, action) => {
        state.loading = false;
        const {
          arg: { id },
        } = action.meta;
        if (id) {
          state.abouts = state.abouts.filter((ac) => ac._id !== id);
        }
      })
      .addCase(deleteAbout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message;
      });
  },
});

export const { resetError } = aboutSlice.actions;
export default aboutSlice.reducer;
