import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "../admindash.css";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  getAbout,
  resetError,
  updateAbout,
} from "../../../../redux/features/aboutSlice";

const UpdateAbout = () => {
  const [states, setState] = useState({
    title: "",
  });
  const [description, setDescription] = useState("");
  const [vision, setVision] = useState("");
  const [mission, setMission] = useState("");
  const [goals, setGoals] = useState("");
  const [coreValues, setCoreValues] = useState("");

  const { error, loading, about } = useSelector((state) => state.about);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (!loading) {
      setState({
        title: about.title || "",
      });
      setDescription(about.description);
      setVision(about.vision);
      setMission(about.mission);
      setGoals(about.goals);
      setCoreValues(about.core_values);
    }
  }, [loading, about]);

  const handleInput = (e) => {
    setState({
      ...states,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (params.id) {
      dispatch(getAbout(params.id));
    }
  }, [dispatch, params.id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateAbout({
        id: params.id,
        formData: {
          title: states.title,
          description: description,
          vision: vision,
          mission: mission,
          goals: goals,
          core_values: coreValues,
        },
        navigate,
        toast,
      })
    );
  };

  useEffect(() => {
    error && toast.error(error);
    dispatch(resetError());
  }, [error, dispatch]);
  return (
    <div className="inner_form">
      <div className="container pt-lg-5 pb-lg-5">
        <div className="create_form p-5">
          <h2 className="text-center">Update About Content</h2>
          <form onSubmit={handleSubmit}>
            <div className="form_control">
              <label>Title</label>
              <input
                type="text"
                id="title"
                name="title"
                value={states.title}
                onChange={handleInput}
              />
            </div>
            <div className="form_control">
              <label>Description</label>
              <ReactQuill
                theme="snow"
                value={description}
                onChange={setDescription}
                style={{ height: "300px" }}
                placeholder={"Write some descriptions..."}
              />
            </div>
            <br /> <br />
            <div className="form_control">
              <label>Vision</label>
              <ReactQuill
                theme="snow"
                value={vision}
                onChange={setVision}
                style={{ height: "300px" }}
                placeholder={"Write some text..."}
              />
            </div>
            <br /> <br />
            <div className="form_control">
              <label>Mission</label>
              <ReactQuill
                theme="snow"
                value={mission}
                onChange={setMission}
                style={{ height: "300px" }}
                placeholder={"Write some text..."}
              />
            </div>
            <br /> <br />
            <div className="form_control">
              <label>Goals</label>
              <ReactQuill
                theme="snow"
                value={goals}
                onChange={setGoals}
                style={{ height: "300px" }}
                placeholder={"Write some text..."}
              />
            </div>
            <br /> <br />
            <div className="form_control">
              <label>Core Values</label>
              <ReactQuill
                theme="snow"
                value={coreValues}
                onChange={setCoreValues}
                style={{ height: "300px" }}
                placeholder={"Write some text..."}
              />
            </div>
            <br />
            <br />
            <div className="mt-4">
              <button>Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateAbout;
