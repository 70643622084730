import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const createAdmission = createAsyncThunk(
  "admission/createAdmission",
  async ({ formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.admission(formData);
      toast.success("Admission content added Successfully!!");
      navigate("/admin/admission");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAdmissions = createAsyncThunk(
  "admission/getAdmissions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.admissions();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAdmission = createAsyncThunk(
  "admission/getAdmission",
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.singleAdmission(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateAdmission = createAsyncThunk(
  "admission/updateAdmission",
  async ({ id, formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.updateAdmission(id, formData);
      toast.success("Admission content updated Successfully");
      navigate("/admin/admission");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateAdmissionImage = createAsyncThunk(
  "admission/updateAdmissionImage",
  async ({ id, formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.admissionImageUpdate(id, formData);
      toast.success("Admission image updated Successfully");
      navigate("/admin/admission");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAdmission = createAsyncThunk(
  "admission/deleteAdmission",
  async ({ id, toast }, { rejectWithValue }) => {
    try {
      const response = await api.deleteAdmission(id);
      toast.success("Admission content deleted Successfully");
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

const admissionSlice = createSlice({
  name: "admission",
  initialState: {
    admission: {},
    admissions: [],
    error: "",
    loading: false,
  },
  reducers: {
    resetError: (state, action) => {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAdmission.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(createAdmission.fulfilled, (state, action) => {
        state.loading = false;
        state.admissions = action.payload;
      })
      .addCase(createAdmission.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(getAdmissions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAdmissions.fulfilled, (state, action) => {
        state.loading = false;
        state.admissions = action.payload;
      })
      .addCase(getAdmissions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(getAdmission.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAdmission.fulfilled, (state, action) => {
        state.loading = false;
        state.admission = action.payload;
      })
      .addCase(getAdmission.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(updateAdmission.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAdmission.fulfilled, (state, action) => {
        state.loading = false;
        state.admission = action.payload;
      })
      .addCase(updateAdmission.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(updateAdmissionImage.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAdmissionImage.fulfilled, (state, action) => {
        state.loading = false;
        state.admission = action.payload;
      })
      .addCase(updateAdmissionImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(deleteAdmission.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(deleteAdmission.fulfilled, (state, action) => {
        state.loading = false;
        const {
          arg: { id },
        } = action.meta;
        if (id) {
          state.admissions = state.admissions.filter((ac) => ac._id !== id);
        }
      })
      .addCase(deleteAdmission.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message;
      });
  },
});

export const { resetError } = admissionSlice.actions;
export default admissionSlice.reducer;
