import { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { resetError } from "../../../../redux/features/aboutSlice";
import "../admindash.css";
import { getFaculties } from "../../../../redux/features/facultySlice";
import { createSemester } from "../../../../redux/features/semesterSlice";

const AddSemester = () => {
  const [states, setState] = useState({
    title: "",
    faculty: "",
  });

  const { error } = useSelector((state) => state.semester);
  const { faculties } = useSelector((state) => state.faculty);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleInput = (e) => {
    setState({
      ...states,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    dispatch(getFaculties());
  }, [dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      createSemester({
        formData: states,
        navigate,
        toast,
      })
    );
  };
  useEffect(() => {
    error && toast.error(error);
    dispatch(resetError());
  }, [error, dispatch]);
  return (
    <div className="inner_form">
      <div className="container pt-lg-5 pb-lg-5">
        <div className="create_form p-5">
          <h2 className="text-center">Create Semester</h2>
          <form onSubmit={handleSubmit}>
            <div className="form_control">
              <label>Title</label>
              <input
                type="text"
                id="title"
                name="title"
                value={states.title}
                onChange={handleInput}
              />
            </div>

            <div className="form_control">
              <label>Faculty</label>
              <select name="faculty" onChange={handleInput}>
                <option>Please select</option>
                {faculties &&
                  faculties?.map((f) => (
                    <option key={f._id} value={f._id}>
                      {f.title}
                    </option>
                  ))}
              </select>
            </div>
            <div className="mt-4">
              <button>Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddSemester;
