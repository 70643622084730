import React from "react";
import "./admindash.css";
import AdminNav from "./AdminNav";
import AdminTopNav from "./AdminTopNav";
import { useSelector } from "react-redux";

const AdminDashboard = () => {
  const { user } = useSelector((state) => state.auth);
  return (
    <div className="dash">
      <>
        <div id="mySidenav" className="sidenav">
          <AdminNav />
        </div>
        <div id="main">
          <AdminTopNav user={user} />

          <div className="clearfix" />
          <br />
          <br />
          <br />
          <div className="col-div-3">
            <div className="box">
              <p>
                All
                <br />
                <span>Users</span>
              </p>
              <i className="fa fa-users box-icon" />
            </div>
          </div>
          <div className="col-div-3">
            <div className="box">
              <p>
                100
                <br />
                <span>Subjects</span>
              </p>
              <i className="fa fa-list box-icon" />
            </div>
          </div>
          <div className="col-div-3">
            <div className="box">
              <p>
                20
                <br />
                <span>Teachers</span>
              </p>
              <i className="fa fa-shopping-bag box-icon" />
            </div>
          </div>
          <div className="col-div-3">
            <div className="box">
              <p>
                4
                <br />
                <span>Academics</span>
              </p>
              <i className="fa fa-tasks box-icon" />
            </div>
          </div>

          <div className="clearfix" />
          <div className="mt-5"></div>
          <div className="clearfix" />
          <br />
          <div className="col-div-3">
            <div className="box">
              <p>
                100
                <br />
                <span>Notes</span>
              </p>
              <i className="fa fa-users box-icon" />
            </div>
          </div>
          <div className="col-div-3">
            <div className="box">
              <p>
                10
                <br />
                <span>Events</span>
              </p>
              <i className="fa fa-list box-icon" />
            </div>
          </div>
          <div className="col-div-3">
            <div className="box">
              <p>
                20
                <br />
                <span>Teachers</span>
              </p>
              <i className="fa fa-shopping-bag box-icon" />
            </div>
          </div>
          <div className="col-div-3">
            <div className="box">
              <p>
                1000
                <br />
                <span>Questions</span>
              </p>
              <i className="fa fa-tasks box-icon" />
            </div>
          </div>
          <div className="clearfix" />
        </div>
      </>
    </div>
  );
};

export default AdminDashboard;
