import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const createApplication = createAsyncThunk(
  "application/createApplication",
  async ({ state, navigate, toast }, { rejectWithValue }) => {
    try {
      console.log(state);
      const response = await api.application(state);
      toast.success("Thank for your application. We will contact you soon.");
      navigate("/apply-online");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getApplications = createAsyncThunk(
  "application/getApplications",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.applications();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getApplication = createAsyncThunk(
  "application/getApplication",
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.singleapplication(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateApplication = createAsyncThunk(
  "application/updateApplication",
  async ({ id, isView }, { rejectWithValue }) => {
    try {
      const response = await api.updateapplication(id, isView);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteApplication = createAsyncThunk(
  "application/deleteApplication",
  async ({ id, toast }, { rejectWithValue }) => {
    try {
      const response = await api.deleteapplication(id);
      toast.success("Application deleted Successfully");
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

const applicationSlice = createSlice({
  name: "application",
  initialState: {
    application: {},
    applications: [],
    error: "",
    loading: false,
  },
  reducers: {
    resetError: (state, action) => {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createApplication.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(createApplication.fulfilled, (state, action) => {
        state.loading = false;
        state.applications = action.payload;
      })
      .addCase(createApplication.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(getApplications.pending, (state) => {
        state.loading = true;
      })
      .addCase(getApplications.fulfilled, (state, action) => {
        state.loading = false;
        state.applications = action.payload;
      })
      .addCase(getApplications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(getApplication.pending, (state) => {
        state.loading = true;
      })
      .addCase(getApplication.fulfilled, (state, action) => {
        state.loading = false;
        state.application = action.payload;
      })
      .addCase(getApplication.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(updateApplication.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateApplication.fulfilled, (state, action) => {
        state.loading = false;
        state.application = action.payload;
      })
      .addCase(updateApplication.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(deleteApplication.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(deleteApplication.fulfilled, (state, action) => {
        state.loading = false;
        const {
          arg: { id },
        } = action.meta;
        if (id) {
          state.applications = state.applications.filter((ac) => ac._id !== id);
        }
      })
      .addCase(deleteApplication.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message;
      });
  },
});

export const { resetError } = applicationSlice.actions;
export default applicationSlice.reducer;
