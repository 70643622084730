import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import {
  resetError,
  updateNoticeImage,
} from "../../../../redux/features/noticeSlice";

const UpdateNoticeImage = () => {
  const [state, setState] = useState({
    image: "",
    imagePreview: "",
    imageName: "Choose Image: ",
  });
  const { error } = useSelector((state) => state.notice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const fileHandle = (e) => {
    if (e.target.files.length !== 0) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setState({
          ...state,
          imagePreview: reader.result,
          image: e.target.files[0],
          imageName: e.target.files[0].name,
        });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", state.image);

    dispatch(
      updateNoticeImage({
        id: params.id,
        formData: formData,
        navigate,
        toast,
      })
    );
  };
  useEffect(() => {
    error && toast.error(error);
    dispatch(resetError());
  }, [error, dispatch]);
  return (
    <div className="inner_form">
      <div className="container pt-lg-5 pb-lg-5">
        <div className="create_form p-5">
          <h2 className="text-center">Update Notice Image </h2>
          <form onSubmit={handleSubmit}>
            <div className="form_control">
              <label>Add Image </label>
              <input
                type="file"
                id="image"
                name="image"
                onChange={fileHandle}
              />
            </div>
            <div className="image_preview">
              {state.imagePreview ? (
                <img src={state.imagePreview} alt="_image" className="w-100" />
              ) : (
                <img src={state.currentImage} alt="_image" className="w-100" />
              )}
            </div>

            <div className="mt-4">
              <button>Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateNoticeImage;
