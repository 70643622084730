import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "../admindash.css";
import {
  getApplication,
  resetError,
} from "../../../../redux/features/applicationSlice";

const SingleApplication = () => {
  const { error, application } = useSelector((state) => state.application);
  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    if (params.id) {
      dispatch(getApplication(params.id));
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    error && toast.error(error);
    dispatch(resetError());
  }, [error, dispatch]);
  return (
    <div className="inner_form">
      <div className="container pt-lg-5 pb-lg-5">
        <div className="create_form p-5">
          <h2 className="text-center">Applicant Details</h2>
          <div className="message">
            <p>Name: {application.name}</p>
            <p>Email: {application.email}</p>
            <p>Address: {application.address}</p>
            <p>Contact Number: {application.phone}</p>
            <p>Interested Program: {application.program}</p>
            <p>Previous College: {application.prev_college}</p>
            <p>
              Message: <br />
              {application.message}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleApplication;
