import React, { Suspense, lazy, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Footer from "./components/footer/Footer";
import HeaderFirst from "./components/header/HeaderFirst";
import HeaderSecond from "./components/header/HeaderSecond";
import Notfound from "./notfound/Notfound";
import Login from "./pages/auth/Login";
import StudentLogin from "./pages/auth/StudentLogin";
import AdminDashboard from "./pages/dashboard/admin/AdminDashboard";
import AboutContents from "./pages/dashboard/admin/about/AboutContents";
import CreateAbout from "./pages/dashboard/admin/about/CreateAbout";
import UpdateAbout from "./pages/dashboard/admin/about/UpdateAbout";
import UpdateAboutImage from "./pages/dashboard/admin/about/UpdateAboutImage";
import AdmissionContent from "./pages/dashboard/admin/admission/AdmissionContents";
import CreateAdmContent from "./pages/dashboard/admin/admission/CreateAdmContent";
import UpdateAdmission from "./pages/dashboard/admin/admission/UpdateAdmission";
import UpdateAdmissionImage from "./pages/dashboard/admin/admission/UpdateAdmissionImage";
import AllApplication from "./pages/dashboard/admin/application/AllApplication";
import SingleApplication from "./pages/dashboard/admin/application/SingleApplication";
import Banners from "./pages/dashboard/admin/banner/Banners";
import CreateBanner from "./pages/dashboard/admin/banner/CreateBanner";
import UpdateBanner from "./pages/dashboard/admin/banner/UpdateBanner";
import UpdateBannerImage from "./pages/dashboard/admin/banner/UpdateBannerImage";
import AllContacts from "./pages/dashboard/admin/contact/AllContacts";
import SingleContact from "./pages/dashboard/admin/contact/SingleContact";
import AllFaculties from "./pages/dashboard/admin/faculty/AllFaculties";
import CreateFaculty from "./pages/dashboard/admin/faculty/CreateFaculty";
import UpdateFaculty from "./pages/dashboard/admin/faculty/UpdateFaculty";
import UpdateFacultyImage from "./pages/dashboard/admin/faculty/UpdateFacultyImage";
import AddFaq from "./pages/dashboard/admin/faq/AddFaq";
import AllFaq from "./pages/dashboard/admin/faq/AllFaq";
import UpdateFaq from "./pages/dashboard/admin/faq/UpdateFaq";
import UpdateFaqImage from "./pages/dashboard/admin/faq/UpdateFaqImage";
import AddGallery from "./pages/dashboard/admin/gallery/AddGallery";
import Gallerys from "./pages/dashboard/admin/gallery/Gallerys";
import UpdateGallery from "./pages/dashboard/admin/gallery/UpdateGallery";
import UpdateGalleryImage from "./pages/dashboard/admin/gallery/UpdateGalleryImage";
import AddNews from "./pages/dashboard/admin/news/AddNews";
import AllNews from "./pages/dashboard/admin/news/AllNews";
import UpdateNews from "./pages/dashboard/admin/news/UpdateNews";
import UpdateNewsImage from "./pages/dashboard/admin/news/UpdateNewsImage";
import AddNotice from "./pages/dashboard/admin/notice/AddNotice";
import Notices from "./pages/dashboard/admin/notice/Notices";
import UpdateNotice from "./pages/dashboard/admin/notice/UpdateNotice";
import UpdateNoticeImage from "./pages/dashboard/admin/notice/UpdateNoticeImage";
import AddPartner from "./pages/dashboard/admin/partners/AddPartner";
import Partners from "./pages/dashboard/admin/partners/Partners";
import UpdatePartner from "./pages/dashboard/admin/partners/UpdatePartner";
import UpdatePartnerImage from "./pages/dashboard/admin/partners/UpdatePartnerImage";
import AddPmessage from "./pages/dashboard/admin/pmessage/AddPmessage";
import AllPMessages from "./pages/dashboard/admin/pmessage/AllPMessages";
import UpdatePmessage from "./pages/dashboard/admin/pmessage/UpdatePmessage";
import UpdatePmessageImage from "./pages/dashboard/admin/pmessage/UpdatePmessageImage";
import CreateScholarship from "./pages/dashboard/admin/scholarship/CreateScholarship";
import ScholarshipContent from "./pages/dashboard/admin/scholarship/ScholarshipContent";
import UpdateScholarship from "./pages/dashboard/admin/scholarship/UpdateScholarShip";
import UpdateScholarshipImage from "./pages/dashboard/admin/scholarship/UpdateScholarshipImage";
import AddSemester from "./pages/dashboard/admin/semester/AddSemester";
import AllSemester from "./pages/dashboard/admin/semester/AllSemester";
import AddTestimonial from "./pages/dashboard/admin/testimonial/AddTestimonial";
import AllTestimonials from "./pages/dashboard/admin/testimonial/AllTestimonials";
import UpdatetesTimonial from "./pages/dashboard/admin/testimonial/UpdateTestimonial";
import UpdateTestimonialImage from "./pages/dashboard/admin/testimonial/UpdateTestimonialImage";
import StudentDashboard from "./pages/dashboard/student/StudentDashboard";
import TeacherDashboard from "./pages/dashboard/teacher/TeacherDashboard";
import { setUser } from "./redux/features/authSlice";
import AdminRoute from "./routes/AdminRoute";
import StudentRoute from "./routes/StudentRoute";
import TeacherRoute from "./routes/TeacherRoute";
import NewsDetails from "./pages/details/NewsDetails";
import Loading from "./components/loading/Loading";
import Chat from "./components/constants/Chat";

const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const Admissions = lazy(() => import("./pages/Admissions"));
const Apply = lazy(() => import("./pages/Apply"));
const Contact = lazy(() => import("./pages/Contact"));
const Faq = lazy(() => import("./pages/Faq"));
const Gallery = lazy(() => import("./pages/Gallery"));
const News = lazy(() => import("./pages/News"));
const Scholarships = lazy(() => import("./pages/Scholarships"));
const Notice = lazy(() => import("./pages/Notices"));
const CourseDetails = lazy(() => import("./pages/details/CourseDetails"));
const GalleryDetails = lazy(() => import("./pages/details/GalleryDetails"));
const MessageDetails = lazy(() => import("./pages/details/MessageDetails"));
const NoticeDetails = lazy(() => import("./pages/details/NoticeDetails"));

const App = () => {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("profile"));

  useEffect(() => {
    dispatch(setUser(user));
  }, [dispatch, user]);

  const [position, setPosition] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  useEffect(() => {
    const handleScroll = () => {
      let moving = window.pageYOffset;

      setVisible(position > moving);
      setPosition(moving);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  const cls = visible ? "visible" : "hidden";

  const [showHeaderFooter, setShowHeaderFooter] = useState(true);
  let currentPath = window.location.pathname;
  useEffect(() => {
    const isNotAdminDashboard = !currentPath.startsWith("/admin/");
    setShowHeaderFooter(isNotAdminDashboard);
  }, [currentPath, showHeaderFooter]);

  return (
    <>
      <Suspense fallback={<Loading />}>
        <Router>
          {showHeaderFooter && (
            <header className={cls}>
              <HeaderFirst />
              <HeaderSecond />
            </header>
          )}
          <ToastContainer />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/notices" element={<Notice />} />
            <Route path="/notice/:slug/:id" element={<NoticeDetails />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/gallery/:slug/:id" element={<GalleryDetails />} />
            <Route path="/who-we-are" element={<About />} />
            <Route path="/why-us" element={<About />} />
            <Route path="/academics/:slug/:id" element={<CourseDetails />} />
            <Route path="/admissions" element={<Admissions />} />
            <Route path="/scholarships" element={<Scholarships />} />
            <Route path="/faqs" element={<Faq />} />
            <Route path="/news-and-events" element={<News />} />
            <Route
              path="/news-and-events/:slug/:id"
              element={<NewsDetails />}
            />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/message" element={<MessageDetails />} />
            <Route path="/apply-online" element={<Apply />} />
            <Route path="/admin/login" element={<Login />} />
            <Route path="/login" element={<StudentLogin />} />

            {/* student route */}
            <Route path="/student/" element={<StudentRoute />}>
              <Route path="dashboard" element={<StudentDashboard />} />
            </Route>
            {/* teacher route */}
            <Route path="/teacher/" element={<TeacherRoute />}>
              <Route path="dashboard" element={<TeacherDashboard />} />
            </Route>
            {/* admin route */}

            <Route path="/admin/" element={<AdminRoute />}>
              <Route path="dashboard" element={<AdminDashboard />} />
              {/* banners */}
              <Route path="banners" element={<Banners />} />
              <Route path="add-banner" element={<CreateBanner />} />
              <Route path="update-banner/:id" element={<UpdateBanner />} />
              <Route
                path="update-banner-image/:id"
                element={<UpdateBannerImage />}
              />
              {/* about */}
              <Route path="abouts" element={<AboutContents />} />
              <Route path="add-about" element={<CreateAbout />} />
              <Route path="update-about/:id" element={<UpdateAbout />} />
              <Route
                path="update-about-image/:id"
                element={<UpdateAboutImage />}
              />

              {/* messages */}
              <Route path="pmessages" element={<AllPMessages />} />
              <Route path="add-pmessage" element={<AddPmessage />} />
              <Route path="update-pmessage/:id" element={<UpdatePmessage />} />
              <Route
                path="update-pmessage-image/:id"
                element={<UpdatePmessageImage />}
              />
              {/* admission */}
              <Route path="admission" element={<AdmissionContent />} />
              <Route path="add-admission" element={<CreateAdmContent />} />
              <Route
                path="update-admission/:id"
                element={<UpdateAdmission />}
              />
              <Route
                path="update-admission-image/:id"
                element={<UpdateAdmissionImage />}
              />
              {/* scholarships */}
              <Route path="scholarship" element={<ScholarshipContent />} />
              <Route path="add-scholarship" element={<CreateScholarship />} />
              <Route
                path="update-scholarship/:id"
                element={<UpdateScholarship />}
              />
              <Route
                path="update-scholarship-image/:id"
                element={<UpdateScholarshipImage />}
              />
              {/*faculties  */}
              <Route path="faculties" element={<AllFaculties />} />
              <Route path="add-faculty" element={<CreateFaculty />} />
              <Route path="update-faculty/:id" element={<UpdateFaculty />} />
              <Route
                path="update-faculty-image/:id"
                element={<UpdateFacultyImage />}
              />
              {/* news and events */}
              <Route path="news-and-events" element={<AllNews />} />
              <Route path="add-news-and-events" element={<AddNews />} />
              <Route
                path="update-news-and-events/:id"
                element={<UpdateNews />}
              />
              <Route
                path="update-news-and-events-image/:id"
                element={<UpdateNewsImage />}
              />
              {/* faq */}
              <Route path="faqs" element={<AllFaq />} />
              <Route path="add-faqs" element={<AddFaq />} />
              <Route path="update-faqs/:id" element={<UpdateFaq />} />
              <Route
                path="update-faqs-image/:id"
                element={<UpdateFaqImage />}
              />
              {/* contact */}
              <Route path="contacts" element={<AllContacts />} />
              <Route path="contact/:id" element={<SingleContact />} />
              {/* application */}
              <Route path="applications" element={<AllApplication />} />
              <Route path="application/:id" element={<SingleApplication />} />
              {/* semester */}
              <Route path="semesters" element={<AllSemester />} />
              <Route path="add-semester" element={<AddSemester />} />
              {/* testimonials */}
              <Route path="testimonials" element={<AllTestimonials />} />
              <Route path="add-testimonial" element={<AddTestimonial />} />
              <Route
                path="update-testimonial/:id"
                element={<UpdatetesTimonial />}
              />
              <Route
                path="update-testimonial-image/:id"
                element={<UpdateTestimonialImage />}
              />
              {/* gallery */}
              <Route path="gallery" element={<Gallerys />} />
              <Route path="add-gallery" element={<AddGallery />} />
              <Route path="update-gallery/:id" element={<UpdateGallery />} />
              <Route
                path="update-gallery-image/:id"
                element={<UpdateGalleryImage />}
              />
              {/* partners */}
              <Route path="partners" element={<Partners />} />
              <Route path="add-partner" element={<AddPartner />} />
              <Route path="update-partner/:id" element={<UpdatePartner />} />
              <Route
                path="update-partner-image/:id"
                element={<UpdatePartnerImage />}
              />
              {/* notice */}
              <Route path="notices" element={<Notices />} />
              <Route path="add-notice" element={<AddNotice />} />
              <Route path="update-notice/:id" element={<UpdateNotice />} />
              <Route
                path="update-notice-image/:id"
                element={<UpdateNoticeImage />}
              />
            </Route>

            <Route path="*" element={<Notfound />} />
          </Routes>
          {showHeaderFooter && <Footer />}
        </Router>
      </Suspense>
      <div className="chat">
        <Chat />
      </div>
    </>
  );
};

export default App;
