import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const createBanner = createAsyncThunk(
  "banner/createBanner",
  async ({ formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.banner(formData);
      toast.success("Banner added Successfully");
      navigate("/admin/banners");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getBanners = createAsyncThunk(
  "banner/getBanners",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.banners();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getBanner = createAsyncThunk(
  "banner/getBanner",
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.singleBanner(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateBanner = createAsyncThunk(
  "banner/updateBanner",
  async ({ id, formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.updateBanner(id, formData);
      toast.success("Banner updated Successfully");
      navigate("/admin/banners");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateBannerImage = createAsyncThunk(
  "banner/updateBannerImage",
  async ({ id, formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.bannerImage(id, formData);
      toast.success("Banner image updated Successfully");
      navigate("/admin/banners");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteBanner = createAsyncThunk(
  "banner/deleteBanner",
  async ({ id, toast }, { rejectWithValue }) => {
    try {
      const response = await api.deleteBanner(id);
      toast.success("Banner deleted Successfully");
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

const bannerSlice = createSlice({
  name: "banner",
  initialState: {
    banner: {},
    banners: [],
    error: "",
    loading: false,
  },
  reducers: {
    resetError: (state, action) => {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createBanner.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(createBanner.fulfilled, (state, action) => {
        state.loading = false;
        state.banners = action.payload;
      })
      .addCase(createBanner.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(getBanners.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getBanners.fulfilled, (state, action) => {
        state.loading = false;
        state.banners = action.payload;
      })
      .addCase(getBanners.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(getBanner.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getBanner.fulfilled, (state, action) => {
        state.loading = false;
        state.banner = action.payload;
      })
      .addCase(getBanner.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(updateBanner.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateBanner.fulfilled, (state, action) => {
        state.loading = false;
        state.banner = action.payload;
      })
      .addCase(updateBanner.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(updateBannerImage.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateBannerImage.fulfilled, (state, action) => {
        state.loading = false;
        state.banner = action.payload;
      })
      .addCase(updateBannerImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(deleteBanner.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(deleteBanner.fulfilled, (state, action) => {
        state.loading = false;
        const {
          arg: { id },
        } = action.meta;
        if (id) {
          state.banners = state.banners.filter((ac) => ac._id !== id);
        }
      })
      .addCase(deleteBanner.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message;
      });
  },
});

export const { resetError } = bannerSlice.actions;
export default bannerSlice.reducer;
