import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const createTestimonial = createAsyncThunk(
  "testimonial/createTestimonial",
  async ({ formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.testimonial(formData);
      toast.success("Testimonial added Successfully");
      navigate("/admin/testimonials");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getTestimonials = createAsyncThunk(
  "testimonial/getTestimonials",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.testimonials();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getTestimonial = createAsyncThunk(
  "testimonial/getTestimonial",
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.singletestimonial(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateTestimonial = createAsyncThunk(
  "testimonial/updateTestimonial",
  async ({ id, formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.updatetestimonial(id, formData);
      toast.success("Testimonial updated Successfully");
      navigate("/admin/testimonials");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateTestimonialImage = createAsyncThunk(
  "testimonial/updateTestimonialImage",
  async ({ id, formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.testimonialImageUpdate(id, formData);
      toast.success("Testimonial image updated Successfully");
      navigate("/admin/testimonials");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteTestimonial = createAsyncThunk(
  "testimonial/deleteTestimonial",
  async ({ id, toast }, { rejectWithValue }) => {
    try {
      const response = await api.deletetestimonial(id);
      toast.success("Testimonial deleted Successfully");
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

const testimonialSlice = createSlice({
  name: "testimonial",
  initialState: {
    testimonial: {},
    testimonials: [],
    error: "",
    loading: false,
  },
  reducers: {
    resetError: (state, action) => {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTestimonial.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(createTestimonial.fulfilled, (state, action) => {
        state.loading = false;
        state.testimonials = action.payload;
      })
      .addCase(createTestimonial.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(getTestimonials.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getTestimonials.fulfilled, (state, action) => {
        state.loading = false;
        state.testimonials = action.payload;
      })
      .addCase(getTestimonials.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(getTestimonial.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getTestimonial.fulfilled, (state, action) => {
        state.loading = false;
        state.testimonial = action.payload;
      })
      .addCase(getTestimonial.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(updateTestimonial.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateTestimonial.fulfilled, (state, action) => {
        state.loading = false;
        state.testimonial = action.payload;
      })
      .addCase(updateTestimonial.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(updateTestimonialImage.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateTestimonialImage.fulfilled, (state, action) => {
        state.loading = false;
        state.testimonial = action.payload;
      })
      .addCase(updateTestimonialImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(deleteTestimonial.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(deleteTestimonial.fulfilled, (state, action) => {
        state.loading = false;
        const {
          arg: { id },
        } = action.meta;
        if (id) {
          state.testimonials = state.testimonials.filter((ac) => ac._id !== id);
        }
      })
      .addCase(deleteTestimonial.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message;
      });
  },
});

export const { resetError } = testimonialSlice.actions;
export default testimonialSlice.reducer;
