import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import {
  addGalleryImage,
  deleteGalleryImage,
  getGallery,
  resetError,
} from "../../../../redux/features/gallerySlice";

const UpdateGalleryImage = () => {
  const [states, setState] = useState({
    image: [],
  });
  const { error, loading, gallery } = useSelector((state) => state.gallery);
  const [imagePreviews, setImagePreviews] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const fileHandle = (e) => {
    if (e.target.files.length !== 0) {
      const selectedImages = Array.from(e.target.files);

      setState({
        ...states,
        image: [...states.image, ...selectedImages], // Update the array with new images
      });

      const previews = [...imagePreviews];
      selectedImages.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          previews.push(reader.result);
          setImagePreviews([...previews]);
        };
        reader.readAsDataURL(file);
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { image } = states;
    const formData = new FormData();
    image.forEach((image, index) => {
      formData.append("image", image); // Use the same key for each image
    });
    dispatch(addGalleryImage({ id: params.id, formData, navigate, toast }));
  };

  const removeImage = (index) => {
    const updatedImages = [...states.image];
    updatedImages.splice(index, 1);

    setState({
      ...states,
      image: updatedImages,
    });

    setImagePreviews(updatedImages.map((file) => URL.createObjectURL(file)));
  };

  const deletegalleryImg = async (index) => {
    const confirm = window.confirm("Are you really want to delete this image?");
    if (confirm) {
      await dispatch(deleteGalleryImage({ id: params.id, index, toast }));
      dispatch(getGallery(params.id));
    }
  };

  useEffect(() => {
    if (params.id) {
      dispatch(getGallery(params.id));
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    error && toast.error(error);
    dispatch(resetError());
  }, [error, dispatch]);

  return (
    <div className="inner_form">
      <div className="container pt-lg-5 pb-lg-5">
        <div className="create_form p-5">
          <h2 className="text-center">Update Gallery Image </h2>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Col sm={12}>
              <Nav variant="pills" className="flex-row">
                <Nav.Item>
                  <Nav.Link eventKey="first">View Images</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Add Image</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={12} className="mt-3">
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <div
                    className="image_preview"
                    style={{
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    {loading ? (
                      <></>
                    ) : (
                      <>
                        {" "}
                        {gallery &&
                          gallery.image?.map((preview, index) => (
                            <div
                              key={index}
                              className="image_perv"
                              style={{ width: "200px" }}
                            >
                              <img
                                className="w-100"
                                src={`${process.env.REACT_APP_API_IMAGE_URL}${preview}`}
                                height={120}
                                alt={`Preview_${index}`}
                                style={{
                                  border: "2px solid #fff",
                                }}
                              />
                              <IoMdClose
                                onClick={() => deletegalleryImg(index)}
                                className="close_icon"
                              />
                            </div>
                          ))}
                      </>
                    )}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  {" "}
                  <div className="form_control">
                    <label>Image </label>
                    <input
                      type="file"
                      multiple
                      id="image"
                      name="image"
                      onChange={fileHandle}
                    />
                  </div>
                  <div
                    className="image_preview"
                    style={{
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    {imagePreviews.map((preview, index) => (
                      <div
                        key={index}
                        className="image_perv"
                        style={{ width: "200px" }}
                      >
                        <img
                          className="w-100"
                          src={preview}
                          height={120}
                          alt={`Preview_${index}`}
                          style={{
                            border: "2px solid #fff",
                          }}
                        />
                        <IoMdClose
                          onClick={() => removeImage(index)}
                          className="close_icon"
                        />
                      </div>
                    ))}
                  </div>
                  <div className="submit_button">
                    <button onClick={handleSubmit}>Add Image</button>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
};

export default UpdateGalleryImage;
