import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "../admindash.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  resetError,
  createTestimonial,
} from "../../../../redux/features/testimonialSlice";
import { getFaculties } from "../../../../redux/features/facultySlice";
import { getSemesters } from "../../../../redux/features/semesterSlice";

const AddTestimonial = () => {
  const [states, setState] = useState({
    name: "",
    faculty: "",
    semester: "",
    description: "",
    image: [],
  });
  const [filterSemesters, setFilterSemesters] = useState(null);
  const [currentImage, setCurrentImage] = useState("Choose Image");
  const [imagePreview, setImagePreview] = useState();

  const { error } = useSelector((state) => state.testimonial);
  const { faculties } = useSelector((state) => state.faculty);
  const { semesters } = useSelector((state) => state.semester);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const flieHandle = (e) => {
    if (e.target.files.length !== 0) {
      setCurrentImage(e.target.files[0].name);
      setState({
        ...states,
        [e.target.name]: e.target.files[0],
      });
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleInput = (e) => {
    setState({
      ...states,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, faculty, semester, description, image } = states;
    const formData = new FormData();
    formData.append("name", name);
    formData.append("faculty", faculty);
    formData.append("semester", semester);
    formData.append("description", description);
    formData.append("image", image);
    dispatch(createTestimonial({ formData, navigate, toast }));
  };

  const handleFacultySelect = (event) => {
    const selectedFacultyId = event.target.value;
    const selectedSemester = semesters.filter(
      (c) => c?.faculty.title === selectedFacultyId
    );
    setFilterSemesters(selectedSemester);
    setState({
      ...states,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    dispatch(getFaculties());
    dispatch(getSemesters());
  }, [dispatch]);

  useEffect(() => {
    error && toast.error(error);
    dispatch(resetError());
  }, [error, dispatch]);

  return (
    <div className="inner_form">
      <div className="container pt-lg-5 pb-lg-5">
        <div className="create_form p-5">
          <h2 className="text-center">Create Testimonial</h2>
          <form onSubmit={handleSubmit}>
            <div className="form_control">
              <label>Student Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={states.name}
                onChange={handleInput}
              />
            </div>
            <div className="form_control">
              <label>Faculty</label>
              <select name="faculty" onChange={handleFacultySelect}>
                <option>Please select</option>
                {faculties &&
                  faculties?.map((f) => (
                    <option key={f._id} value={f.title}>
                      {f.title}
                    </option>
                  ))}
              </select>
            </div>
            <div className="form_control">
              <label>Semester</label>
              <select name="semester" onChange={handleInput}>
                <option>Please select</option>
                {filterSemesters &&
                  filterSemesters?.map((f) => (
                    <option key={f._id} value={f.title}>
                      {f.title}
                    </option>
                  ))}
              </select>
            </div>
            <div className="form_control">
              <label>Description</label>
              <textarea
                cols={100}
                rows={5}
                type="text"
                id="description"
                name="description"
                value={states.description}
                onChange={handleInput}
              />
            </div>
            <div className="form_control">
              <label>Add Image</label>
              <input
                type="file"
                id="image"
                name="image"
                onChange={flieHandle}
              />
            </div>
            <div className="image_preview">
              {imagePreview ? (
                <img src={imagePreview} alt="_image" className="w-100" />
              ) : (
                <img src={currentImage} alt="_image" className="w-100" />
              )}
            </div>
            <div className="mt-4">
              <button>Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddTestimonial;
