import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const createScholarship = createAsyncThunk(
  "scholarship/createScholarship",
  async ({ formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.scholarship(formData);
      toast.success("Scholarship content added Successfully!!");
      navigate("/admin/scholarship");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getScholarships = createAsyncThunk(
  "scholarship/getScholarships",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.scholarships();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getScholarship = createAsyncThunk(
  "scholarship/getScholarship",
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.singlescholarship(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateScholarship = createAsyncThunk(
  "scholarship/updateScholarship",
  async ({ id, formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.updatescholarship(id, formData);
      toast.success("Scholarship content updated Successfully");
      navigate("/admin/scholarship");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateScholarshipImage = createAsyncThunk(
  "scholarship/updateScholarshipImage",
  async ({ id, formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.scholarshipImageUpdate(id, formData);
      toast.success("Scholarship image updated Successfully");
      navigate("/admin/scholarship");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteScholarship = createAsyncThunk(
  "scholarship/deleteScholarship",
  async ({ id, toast }, { rejectWithValue }) => {
    try {
      const response = await api.deletescholarship(id);
      toast.success("Scholarship content deleted Successfully");
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

const scholarshipSlice = createSlice({
  name: "scholarship",
  initialState: {
    scholarship: {},
    scholarships: [],
    error: "",
    loading: false,
  },
  reducers: {
    resetError: (state, action) => {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createScholarship.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(createScholarship.fulfilled, (state, action) => {
        state.loading = false;
        state.scholarships = action.payload;
      })
      .addCase(createScholarship.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(getScholarships.pending, (state) => {
        state.loading = true;
      })
      .addCase(getScholarships.fulfilled, (state, action) => {
        state.loading = false;
        state.scholarships = action.payload;
      })
      .addCase(getScholarships.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(getScholarship.pending, (state) => {
        state.loading = true;
      })
      .addCase(getScholarship.fulfilled, (state, action) => {
        state.loading = false;
        state.scholarship = action.payload;
      })
      .addCase(getScholarship.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(updateScholarship.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateScholarship.fulfilled, (state, action) => {
        state.loading = false;
        state.scholarship = action.payload;
      })
      .addCase(updateScholarship.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(updateScholarshipImage.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateScholarshipImage.fulfilled, (state, action) => {
        state.loading = false;
        state.scholarship = action.payload;
      })
      .addCase(updateScholarshipImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(deleteScholarship.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(deleteScholarship.fulfilled, (state, action) => {
        state.loading = false;
        const {
          arg: { id },
        } = action.meta;
        if (id) {
          state.scholarships = state.scholarships.filter((ac) => ac._id !== id);
        }
      })
      .addCase(deleteScholarship.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message;
      });
  },
});

export const { resetError } = scholarshipSlice.actions;
export default scholarshipSlice.reducer;
