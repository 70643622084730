import React from "react";

const StudentDashboard = () => {
  // const { user } = useSelector((state) => state.auth);
  // const { faculty_semesters } = useSelector((state) => state.student);

  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(studentFacultyAndSubjects(user?.student._id));
  // }, [dispatch, user?.student._id]);
  // console.log(faculty_semesters);
  return <div>StudentDashboard</div>;
};

export default StudentDashboard;
