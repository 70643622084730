import React from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import { Link } from "react-router-dom";
import "./style.css";

const HeaderFirst = () => {
  return (
    <div className="header_first">
      <div className="navs">
        <div className="college_members">
          <ul>
            <Link to="/notices" className="text-decoration-none text-white">
              <li className="link_border">Notice</li>
            </Link>

            <Link to="/gallery" className="text-decoration-none text-white">
              <li className="link_border">Gallery</li>
            </Link>
            <li className="link_border">Alumini</li>
            {/* <li className="link_border">Faculty</li>
            <li className="link_border">Staff</li> */}
            <a
              href="https://plus2.newsummit.edu.np/"
              target="__blank"
              className="text-decoration-none plus"
            >
              <li className="plus-2">plus - 2</li>
            </a>
          </ul>
        </div>
        <div className="college_contact">
          <ul>
            <li className="link_border">
              <a
                href="tel:01-4620522"
                className="text-white text-decoration-none"
              >
                <FaPhoneAlt /> 01-4620522/23/24
              </a>
            </li>
            <li>
              <a
                href="https://maps.app.goo.gl/RmNu737uysFtZCDS8"
                target="__blank"
                className="text-white text-decoration-none"
              >
                <MdLocationOn /> Shantinagar, Tinkune, Kathmandu
              </a>
            </li>
            <a
              href="https://plus2.newsummit.edu.np/"
              target="__blank"
              className="text-decoration-none plus mbl_plus"
            >
              <li className="plus-2">plus - 2</li>
            </a>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HeaderFirst;
