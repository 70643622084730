import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const createGallery = createAsyncThunk(
  "gallery/createGallery",
  async ({ formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.gallery(formData);
      toast.success("Gallery added Successfully");
      navigate("/admin/gallery");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getGallerys = createAsyncThunk(
  "gallery/getGallerys",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.gallerys();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getGallery = createAsyncThunk(
  "gallery/getGallery",
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.singleGallery(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateGallery = createAsyncThunk(
  "gallery/updategallery",
  async ({ id, formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.galleryUpdate(id, formData);
      toast.success("Gallery updated Successfully");
      navigate("/admin/gallery");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteGalleryImage = createAsyncThunk(
  "gallery/deleteGalleryImage",
  async ({ id, index, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.deleteGalleryImage(id, index);
      toast.success("Gallery image deleted Successfully");
      navigate("/admin/gallery");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const addGalleryImage = createAsyncThunk(
  "gallery/addGalleryImage",
  async ({ id, formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.updateGalleryImage(id, formData);
      toast.success("Gallery image added Successfully");
      navigate("/admin/gallery");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const deleteGallery = createAsyncThunk(
  "gallery/deleteGallery",
  async ({ id, toast }, { rejectWithValue }) => {
    try {
      const response = await api.deleteGallery(id);
      toast.success("Gallery deleted Successfully");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const gallerySlice = createSlice({
  name: "gallery",
  initialState: {
    gallerys: [],
    gallery: {},
    error: "",
    loading: false,
  },
  reducers: {
    resetError: (state, action) => {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createGallery.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(createGallery.fulfilled, (state, action) => {
        state.loading = false;
        state.gallerys = action.payload;
      })
      .addCase(createGallery.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(getGallerys.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGallerys.fulfilled, (state, action) => {
        state.loading = false;

        state.gallerys = action.payload;
      })
      .addCase(getGallerys.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(getGallery.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGallery.fulfilled, (state, action) => {
        state.loading = false;
        state.gallery = action.payload;
      })
      .addCase(getGallery.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(updateGallery.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(updateGallery.fulfilled, (state, action) => {
        state.loading = false;
        state.gallery = action.payload;
      })
      .addCase(updateGallery.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(deleteGalleryImage.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(deleteGalleryImage.fulfilled, (state, action) => {
        state.loading = false;
        state.gallery = action.payload;
      })
      .addCase(deleteGalleryImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message;
      })
      .addCase(addGalleryImage.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(addGalleryImage.fulfilled, (state, action) => {
        state.loading = false;
        state.gallery = action.payload;
      })
      .addCase(addGalleryImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(deleteGallery.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(deleteGallery.fulfilled, (state, action) => {
        state.loading = false;
        const {
          arg: { id },
        } = action.meta;
        if (id) {
          state.gallerys = state.gallerys.filter((ac) => ac._id !== id);
        }
      })
      .addCase(deleteGallery.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });
  },
});

export const { resetError } = gallerySlice.actions;
export default gallerySlice.reducer;
