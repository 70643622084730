import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "./style.css";
import { login } from "../../redux/features/authSlice";

const Login = () => {
  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const { error } = useSelector((state) => state.auth);
  useEffect(() => {
    error && toast.error(error);
  }, [error]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (state.email && state.password) {
      dispatch(login({ state, navigate, toast }));
    }
  };

  const onInputChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="login_page">
      <div className="overlay"></div>
      <div className="container">
        <div className="contact-form ">
          <center>
            <div className="contactForm pt-lg-4 pb-lg-4">
              <form onSubmit={handleSubmit}>
                <h2 className="pt-lg-3 pb-lg-3">Login !</h2>
                <div className="inputBox">
                  <input
                    type="text"
                    name="email"
                    value={state.email}
                    onChange={onInputChange}
                    required="required"
                    placeholder="Email"
                  />
                </div>
                <div className="inputBox">
                  <input
                    type="password"
                    name="password"
                    value={state.password}
                    onChange={onInputChange}
                    required="required"
                    placeholder="Password"
                  />
                </div>
                <div className="inputBox">
                  <input type="submit" defaultValue="Send" />
                </div>
              </form>
            </div>
          </center>
        </div>
      </div>
    </div>
  );
};

export default Login;
