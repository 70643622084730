import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const createNews = createAsyncThunk(
  "news/createNews",
  async ({ formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.news(formData);
      toast.success("News added Successfully!!");
      navigate("/admin/news-and-events");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getNewss = createAsyncThunk(
  "news/getNewss",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.newss();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getNews = createAsyncThunk(
  "news/getNews",
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.singleNews(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateNews = createAsyncThunk(
  "news/updateNews",
  async ({ id, formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.updateNews(id, formData);
      toast.success("News updated Successfully");
      navigate("/admin/news-and-events");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateNewsImage = createAsyncThunk(
  "news/updateNewsImage",
  async ({ id, formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.newsImageUpdate(id, formData);
      toast.success("News image updated Successfully");
      navigate("/admin/news-and-events");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteNews = createAsyncThunk(
  "news/deleteNews",
  async ({ id, toast }, { rejectWithValue }) => {
    try {
      const response = await api.deleteNews(id);
      toast.success("News deleted Successfully");
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

const newsSlice = createSlice({
  name: "news",
  initialState: {
    news: {},
    newss: [],
    error: "",
    loading: false,
  },
  reducers: {
    resetError: (state, action) => {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNews.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(createNews.fulfilled, (state, action) => {
        state.loading = false;
        state.newss = action.payload;
      })
      .addCase(createNews.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(getNewss.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNewss.fulfilled, (state, action) => {
        state.loading = false;
        state.newss = action.payload;
      })
      .addCase(getNewss.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(getNews.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNews.fulfilled, (state, action) => {
        state.loading = false;
        state.news = action.payload;
      })
      .addCase(getNews.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(updateNews.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateNews.fulfilled, (state, action) => {
        state.loading = false;
        state.news = action.payload;
      })
      .addCase(updateNews.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(updateNewsImage.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateNewsImage.fulfilled, (state, action) => {
        state.loading = false;
        state.news = action.payload;
      })
      .addCase(updateNewsImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(deleteNews.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(deleteNews.fulfilled, (state, action) => {
        state.loading = false;
        const {
          arg: { id },
        } = action.meta;
        if (id) {
          state.newss = state.newss.filter((ac) => ac._id !== id);
        }
      })
      .addCase(deleteNews.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message;
      });
  },
});

export const { resetError } = newsSlice.actions;
export default newsSlice.reducer;
