import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const createNotice = createAsyncThunk(
  "notice/createNotice",
  async ({ formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.notice(formData);
      toast.success("Notice added Successfully");
      navigate("/admin/notices");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getNotices = createAsyncThunk(
  "notice/getNotices",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.notices();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getNotice = createAsyncThunk(
  "notice/getNotice",
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.singlenotice(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateNotice = createAsyncThunk(
  "notice/updateNotice",
  async ({ id, formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.updatenotice(id, formData);
      toast.success("Notice updated Successfully");
      navigate("/admin/notices");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateNoticeImage = createAsyncThunk(
  "notice/updateNoticeImage",
  async ({ id, formData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.noticeImageUpdate(id, formData);
      toast.success("notice image updated Successfully");
      navigate("/admin/notices");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteNotice = createAsyncThunk(
  "notice/deleteNotice",
  async ({ id, toast }, { rejectWithValue }) => {
    try {
      const response = await api.deletenotice(id);
      toast.success("Notice deleted Successfully");
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

const noticeSlice = createSlice({
  name: "notice",
  initialState: {
    notice: {},
    notices: [],
    error: "",
    loading: false,
  },
  reducers: {
    resetError: (state, action) => {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNotice.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(createNotice.fulfilled, (state, action) => {
        state.loading = false;
        state.notices = action.payload;
      })
      .addCase(createNotice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(getNotices.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getNotices.fulfilled, (state, action) => {
        state.loading = false;
        state.notices = action.payload;
      })
      .addCase(getNotices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(getNotice.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getNotice.fulfilled, (state, action) => {
        state.loading = false;
        state.notice = action.payload;
      })
      .addCase(getNotice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(updateNotice.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateNotice.fulfilled, (state, action) => {
        state.loading = false;
        state.notice = action.payload;
      })
      .addCase(updateNotice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(updateNoticeImage.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateNoticeImage.fulfilled, (state, action) => {
        state.loading = false;
        state.notice = action.payload;
      })
      .addCase(updateNoticeImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(deleteNotice.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(deleteNotice.fulfilled, (state, action) => {
        state.loading = false;
        const {
          arg: { id },
        } = action.meta;
        if (id) {
          state.notices = state.notices.filter((ac) => ac._id !== id);
        }
      })
      .addCase(deleteNotice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message;
      });
  },
});

export const { resetError } = noticeSlice.actions;
export default noticeSlice.reducer;
