import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getTestimonial,
  resetError,
  updateTestimonial,
} from "../../../../redux/features/testimonialSlice";
import "../admindash.css";
import { getFaculties } from "../../../../redux/features/facultySlice";
import { getSemesters } from "../../../../redux/features/semesterSlice";

const UpdatetesTimonial = () => {
  const [states, setState] = useState({
    name: "",
    faculty: "",
    semester: "",
    description: "",
  });
  const [filterSemesters, setFilterSemesters] = useState(null);
  const { error, loading, testimonial } = useSelector(
    (state) => state.testimonial
  );
  const { faculties } = useSelector((state) => state.faculty);
  const { semesters } = useSelector((state) => state.semester);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (!loading) {
      setState({
        name: testimonial.name || "",
        faculty: testimonial.faculty || "",
        semester: testimonial.semester || "",
        description: testimonial.description || "",
      });
    }
  }, [loading, testimonial]);

  const handleInput = (e) => {
    setState({
      ...states,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (params.id) {
      dispatch(getTestimonial(params.id));
    }
  }, [dispatch, params.id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateTestimonial({
        id: params.id,
        formData: {
          name: states.name,
          faculty: states.faculty,
          semester: states.semester,
          description: states.description,
        },
        navigate,
        toast,
      })
    );
  };
  const handleFacultySelect = (event) => {
    const selectedFacultyId = event.target.value;
    const selectedSemester = semesters.filter(
      (c) => c?.faculty.title === selectedFacultyId
    );
    setFilterSemesters(selectedSemester);
    setState({
      ...states,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    dispatch(getFaculties());
    dispatch(getSemesters());
  }, [dispatch]);
  useEffect(() => {
    error && toast.error(error);
    dispatch(resetError());
  }, [error, dispatch]);
  return (
    <div className="inner_form">
      <div className="container pt-lg-5 pb-lg-5">
        <div className="create_form p-5">
          <h2 className="text-center">Update Testimonial</h2>
          <form onSubmit={handleSubmit}>
            <div className="form_control">
              <label>Student Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={states.name}
                onChange={handleInput}
              />
            </div>
            <div className="form_control">
              <label>Faculty</label>
              <select name="faculty" onChange={handleFacultySelect}>
                <option>{testimonial.faculty}</option>
                {faculties &&
                  faculties?.map((f) => (
                    <option key={f._id} value={f.title}>
                      {f.title}
                    </option>
                  ))}
              </select>
            </div>
            <div className="form_control">
              <label>Semester</label>
              <select name="semester" onChange={handleInput}>
                <option>{testimonial.semester}</option>
                {filterSemesters &&
                  filterSemesters?.map((f) => (
                    <option key={f._id} value={f.title}>
                      {f.title}
                    </option>
                  ))}
              </select>
            </div>
            <div className="form_control">
              <label>Description</label>
              <textarea
                cols={100}
                rows={5}
                type="text"
                id="description"
                name="description"
                value={states.description}
                onChange={handleInput}
              />
            </div>

            <div className="mt-4">
              <button>Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdatetesTimonial;
